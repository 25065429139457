// constants
import { VERSION } from "../constants/constants";

// components
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TreajzLogoBlack from "../assets/treajz-logo.svg";
import { Container, Typography } from "@mui/material";

// hooks
import { useThemeContext } from "../hooks/useThemeContext";
import { usePageContext } from "../hooks/usePageContext";
import { useLocation } from "react-router-dom";

// libraries
import moment from "moment";

export default function Footer() {
  const { theme } = useThemeContext();
  const currentYear = new moment().year();
  const { lastPageUpdate } = usePageContext();
  const location = useLocation();
  const showLastPageUpdate = location.pathname.match(/\/tree\//);

  return (
    <Container maxWidth="xl" sx={{ pb: 7 }}>
      <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
        <Box
          sx={{
            padding: "10px 10px 10px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <Box
              component="img"
              mr={1}
              sx={{
                height: 42,
                filter: theme.palette.mode === "dark" ? "invert(1)" : "invert(0)",
              }}
              alt="Terbeau logo"
              src={TreajzLogoBlack}
            />
            <Typography variant="subtitle2" fontSize={".7rem"} mb={-0.5}>
              &copy; 2020-{currentYear}
            </Typography>
          </Box>
          <Box
            mb={-3}
            sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}
          >
            <Box>
              <Typography variant="subtitle2" fontSize={".7rem"} mb={0} pr={1}>
                {showLastPageUpdate && lastPageUpdate}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" fontSize={".7rem"} mb={0} pr={1}>
                {VERSION}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
