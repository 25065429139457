export class TerbeauUser {
  constructor(uid, name_last, name_first, name_display, privilegesObj) {
    this.uid = uid;
    this.name_last = name_last;
    this.name_first = name_first;
    this.name_display = name_display;
    this.privileges = privilegesObj;
  }

  #getIndexOfAccess(access) {
    const index = this.privileges.findIndex((p) => p.access === access);
    return index;
  }

  postify() {
    const postObj = {};
    postObj.uid = this.uid;
    postObj.privileges = JSON.stringify(JSON.stringify(this.privileges));
    return postObj;
  }

  parse(postObj) {
    this.uid = postObj.uid;
    this.privileges = JSON.parse(JSON.parse(postObj.privileges));
  }

  accessToTree(trees_id) {
    const privilegeWithTree = this.privileges.find((p) => {
      return p.trees.includes(parseInt(trees_id)) || p.trees.includes("all");
    });
    return privilegeWithTree?.access ?? null;
  }

  addAccess(access, trees_id) {
    const index = this.#getIndexOfAccess(access);
    if (index < 0) {
      this.privileges.push({ access, trees: [trees_id] });
      return this;
    }

    if (trees_id === "all") {
      this.privileges[index].trees = [trees_id];
      return this;
    }

    if (this.accessToTree(trees_id) !== access && this.accessToTree(trees_id) !== "all") {
      this.privileges[index].trees.push(trees_id);
      return this;
    }

    return this;
  }

  isAdmin() {
    return this.accessToTree("all") === "admin";
  }

  isGuest() {
    return this.#getIndexOfAccess("guest") > -1;
  }

  isEditor(trees_id) {
    return this.accessToTree(trees_id) === "editor" || this.accessToTree(trees_id) === "admin";
  }

  lastNameFirst() {
    return this.name_last + ", " + this.name_first;
  }

  firstNameFirst() {
    return this.name_first + " " + this.name_last;
  }

  removeAccess(access, trees_id) {
    const index = this.#getIndexOfAccess(access);

    if (index < 0) return this;

    this.privileges[index].trees = this.privileges[index].trees.filter((t) => t !== trees_id);

    return this;
  }
}
