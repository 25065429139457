// components
import App from "./App";
import ReactDOM from "react-dom/client";

// context providers
import { AuthContextProvider } from "./context/AuthContext";
import { ThemeContextProvider } from "./context/ThemeContext";
import { PageContextProvider } from "./context/PageContext";

// styles
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ThemeContextProvider>
    <AuthContextProvider>
      <PageContextProvider>
        <App />
      </PageContextProvider>
    </AuthContextProvider>
  </ThemeContextProvider>
  // </React.StrictMode>
);
