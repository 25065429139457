// components
import Button from "@mui/material/Button";
import { Box, Container, FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material";

// hooks
import { useState } from "react";
import { projectAuth } from "../../firebase/config";
import { useNavigate } from "react-router-dom";

export default function PasswordReset() {
  const [email, setEmail] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    // login(email, password);

    projectAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        setIsPending(false);
        setError(null);
        navigate("/login");
      })
      .catch((error) => {
        setIsPending(false);
        setError(error);
        console.error(error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          padding: "20px",
          marginTop: 10,
          maxWidth: "400px",
          border: "1px solid gray",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h5" m={1} textAlign={"center"}>
          Password Reset
        </Typography>
        <FormControl sx={{ margin: "20px" }}>
          <InputLabel htmlFor="my-input">Email address</InputLabel>
          <OutlinedInput
            type="email"
            variant="outlined"
            id="email"
            label="Email address"
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </FormControl>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "0 20px" }}>
          {!isPending && (
            <Button type="submit" fullWidth variant="contained">
              Send Password Reset to Email
            </Button>
          )}
          {isPending && (
            <Button fullWidth variant="contained" disabled>
              Sending...
            </Button>
          )}
          {error && <div className="error">{error}</div>}
        </Box>
      </Container>
    </form>
  );
}
