// function takes an index of tree pages and the if of the selected page
// returns an array of page indices in sorted order following the index
// page back to the root of the tree

export const pageBreadcrumbs = (pageIndex, pages_id) => {
  // create an array of breadcrumbs from the current page back up to the root
  // following the parents recursively.
  const page = pageIndex.find((p) => p.id === parseInt(pages_id));
  const accumulator = [];
  const fillBreadCrumbs = (page) => {
    const parent = (child) => {
      return pageIndex.find((p) => p.id === child.parent_id);
    };
    if (page.parent_id < 1) {
      accumulator.unshift(page);
      return accumulator;
    }
    accumulator.unshift(page);
    return fillBreadCrumbs(parent(page));
  };
  return page ? fillBreadCrumbs(page) : [];
};
