// functions
import { apiUrl } from "./apiUrl";

export const updateSqlUser = async (terbeauUser) => {
  const url = apiUrl("users/update.php");
  let result = null;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(terbeauUser),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    result = await res.text();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the createSqlUser was aborted");
    } else {
      return "Could not createSqlUser";
    }
  }
  return result.slice(1) === "success";
};
