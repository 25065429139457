// components
import { Button, Typography, CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import ManageProviders from "../../components/ManageProviders";
import GetProvider from "../../components/GetProvider";
import ConfirmDialog from "../../components/ConfirmDialog";

// hooks
import { usePageContext } from "../../hooks/usePageContext";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

// functions
import { postAPI } from "../../functions/postAPI";
import { timestampNowSQL } from "../../functions/timestampNowSQL";
import { apiUrl } from "../../functions/apiUrl";
import { compareProviders } from "../../functions/sortingFunctions";

const TreeProviderMenu = ({ treeProviderData, setTreeProviderData }) => {
  return (
    <>
      {!treeProviderData && (
        <Box
          sx={{ display: "flex", width: "100%", minHeight: "400px", alignItems: "center", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      )}
      {treeProviderData && <ManageProviders dataRows={treeProviderData} setDataRows={setTreeProviderData} />}
    </>
  );
};

export default function Providers() {
  const { user } = useAuthContext();
  const { selectedTreeId, currentTree } = usePageContext();
  const [openGetProvider, setOpenGetProvider] = useState(false);
  const [openConfirmAddProvider, setOpenConfirmAddProvider] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [treeProviderData, setTreeProviderData] = useState(null);

  const handleProviderSelect = (selectedProviderObj, requireConfirm = true) => {
    setSelectedProvider(selectedProviderObj);
    setOpenGetProvider(false);
    if (requireConfirm) {
      setOpenConfirmAddProvider(true);
    } else {
      handleConfirmAddProvider(selectedProviderObj && selectedProviderObj.id);
    }
  };

  const handleConfirmAddProvider = async (confirm) => {
    if (confirm) {
      const success = await postAPI({
        api: "providers/create-providers-trees.php",
        payload: {
          providers_id: selectedProvider.id,
          trees_id: currentTree.id,
          updated_by_uid: user.uid,
          updated_on: timestampNowSQL(),
        },
      });
      if (success) {
        // hot update treeProviderData
        setTreeProviderData((currentState) => {
          return [...currentState, selectedProvider].sort(compareProviders);
        });
      }
    }
    setOpenConfirmAddProvider(false);
  };

  useEffect(() => {
    const controller = new AbortController(); // allow interception of any API request
    const signal = controller.signal; // when we want to cancel the fetch request, we send a signal and the request is cancelled immediately
    // fetch the data and update states...
    const pagesUrl = apiUrl("providers/tree-providers.php", { trees_id: selectedTreeId });
    fetch(pagesUrl, { signal, cache: "reload" }) // signal from AbortController will cancel the request if cleanup function is activated by another request before the api call completes
      .then((res) => res.json())
      .then((data) => {
        setTreeProviderData(data);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.error("cancelled");
        } else {
          //todo:handle error
        }
      });

    // clean up function
    return () => {
      controller.abort();
    };
  }, [selectedTreeId]);

  return (
    <div>
      <Typography variant="h6" color="secondary">
        Manage Providers
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "space-between", padding: ".5rem 0" }}>
        <Typography variant="subtitle1">Tree: {currentTree.name}</Typography>
        <Button variant="outlined" onClick={() => setOpenGetProvider(true)}>
          Add provider to this tree
        </Button>
      </Box>

      <Box>
        <TreeProviderMenu treeProviderData={treeProviderData} setTreeProviderData={setTreeProviderData} />
      </Box>
      <GetProvider
        open={openGetProvider}
        handleClose={handleProviderSelect}
        trees_id={selectedTreeId}
        exclude={true}
        reason={`to add to the ${currentTree.name} tree`}
        allowCreate={true}
      />
      {selectedProvider && (
        <ConfirmDialog
          open={openConfirmAddProvider}
          title={`Confirm ${selectedProvider.first} ${selectedProvider.last}`}
          message={`Are you sure you want to add ${selectedProvider.first} ${selectedProvider.last} to the ${currentTree.name} tree?`}
          handleConfirm={handleConfirmAddProvider}
        />
      )}
    </div>
  );
}
