export const compareStrings = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const compareStringsWithoutCase = (a, b) => compareStrings(a.toUpperCase(), b.toUpperCase());

export const compareNumbers = (a, b) => a - b;

export const compareBooleans = (a, b) => compareNumbers(+a, +b);

export const compareProviders = (a, b) => {
  if (a.custom_sort_order !== b.custom_sort_order) {
    return compareNumbers(a.custom_sort_order, b.custom_sort_order);
  } else if (a.role_sort !== b.role_sort) {
    return compareNumbers(a.role_sort, b.role_sort);
  } else if (a.last !== b.last) {
    return compareStringsWithoutCase(a.last, b.last);
  } else {
    return compareStringsWithoutCase(a.first, b.first);
  }
};

export const compareTreeIndex = (a, b) => {
  if (a.parent_id !== b.parent_id) {
    return compareNumbers(a.parent_id, b.parent_id);
  } else {
    return compareNumbers(a.sort_order, b.sort_order);
  }
};

export const compareSitesBySortOrder = (a, b) => {
  if (a.site_sort !== b.site_sort) {
    return compareNumbers(a.site_sort, b.site_sort);
  } else {
    return compareStrings(a.site, b.site);
  }
};

export const compareTerbeauUsers = (a, b) => {
  if (a.name_last !== b.name_last) {
    return compareStringsWithoutCase(a.name_last, b.name_last);
  } else {
    return compareStringsWithoutCase(a.name_first, b.name_first);
  }
};
