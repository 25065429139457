// constants
import { HOT_UPDATE_FEEDBACK } from "../context/PageContext";

// components
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import { Typography } from "@mui/material";

// hooks
import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { usePageContext } from "../hooks/usePageContext";

// functions
import { timestampNowSQL } from "../functions/timestampNowSQL";
import { updateFeedback } from "../functions/updateFeedback";

export default function FormUpdateFeedback({ feedback_id, default_summary, open, handleClose }) {
  const [updateSummary, setUpdateSummary] = useState(default_summary);
  const { user } = useAuthContext();
  const { dispatch } = usePageContext();
  const [updating, setUpdating] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");

  const validSubmission = () => {
    const valid = updateSummary.trim()?.length > 3;
    return valid;
  };

  const handleUpdate = async () => {
    if (!validSubmission()) return;

    const timestamp = timestampNowSQL();
    // update database with new version
    setUpdating(true);
    const payload = {
      id: feedback_id,
      updated_by_uid: user.uid,
      update_summary: updateSummary,
      update_timestamp: timestamp,
    };

    const success = await updateFeedback(payload);

    setUpdating(false);
    if (!success) {
      setUpdateStatus("Your feedback summary was NOT saved. Please try again or contact your administrator.");
    } else {
      dispatch({ type: HOT_UPDATE_FEEDBACK, action: payload });
      setUpdateStatus("Your feedback summary as been saved.");
    }

    setTimeout(() => {
      setUpdateStatus("");
      setUpdateSummary("");
      handleClose();
    }, 3000);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Update Summary</DialogTitle>
        <DialogContent>
          {!updateStatus && (
            <>
              <DialogContentText>
                Summarize the response to this feedback, including updates to this page and/or communication to the
                clinical leaders.
              </DialogContentText>
              <TextField
                defaultValue={default_summary}
                autoFocus
                margin="dense"
                id="updateSummary"
                label="Update Summary"
                type="text"
                fullWidth
                multiline
                // required
                variant="outlined"
                onChange={(e) => setUpdateSummary(e.target.value)}
                // value={updateSummary}
              />

              <TextField
                margin="dense"
                id="username"
                label="Summary author"
                type="text"
                fullWidth
                disabled
                variant="outlined"
                value={user.terbeauUser.firstNameFirst()}
              />
            </>
          )}
          {updateStatus && <Typography>{updateStatus}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{updateStatus ? "Close" : "Cancel"}</Button>
          {!updateStatus && (
            <LoadingButton
              loading={updating}
              variant="outlined"
              loadingPosition="start"
              startIcon={<Add />}
              onClick={handleUpdate}
              // disabled={!validSuggestion(suggestion.trim())}
              disabled={!validSubmission()}
            >
              <span>Submit Summary</span>
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
