// hooks
import { useState, useEffect } from "react";
import { projectAuth } from "../firebase/config";
import { useNavigate } from "react-router-dom";

// classes
import { TerbeauUser } from "../classes/TerbeauUser";

// functions
import { createSqlUser } from "../functions/createSqlUser";

export const useSignup = () => {
  const navigate = useNavigate();
  const [isCancelled, setIsCancelled] = useState(false);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const signup = async (email, password, nameLast, nameFirst, displayName) => {
    setError(null);
    setIsPending(true);

    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password);

      if (!res) {
        throw new Error("Could not complete signup");
      }

      // add display name to user
      await res.user.updateProfile({ displayName });

      // create a new Terbeau user record with empty privilege set
      const mySqlUser = new TerbeauUser(res.user.uid, nameLast, nameFirst, displayName);

      // put request to users table in mySQL

      const response = await createSqlUser(mySqlUser);

      if (!response.error) {
        navigate(`/`);
      }
      // // dispatch login action
      // dispatch({ type: "LOGIN", payload: res.user });

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return { signup, error, isPending };
};
