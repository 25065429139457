// components
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import FeedbackDataGrid from "../../components/FeedbackDataGrid";

// hooks
import { usePageContext } from "../../hooks/usePageContext";

export default function Feedback() {
  const { selectedTreeId, currentTree } = usePageContext();

  return (
    <div>
      <Typography variant="subtitle2" color="secondary">
        Feedback
      </Typography>
      <Typography variant="subtitle1">{currentTree.name}</Typography>

      <Box>
        <FeedbackDataGrid urlOptions={{ trees_id: selectedTreeId }} isEditor={true} />
      </Box>
    </div>
  );
}
