// functions
import { apiUrl } from "./apiUrl";

export const createPages = async (pagesCreateData) => {
  // use the following object format for pagesCreateData:
  // const sampleData = {
  //   trees_id: 1,
  //   parent_id: 34,
  //   sort_order: 3,
  //   page_title: "Another test insert",
  //   created_by: "MnWVregrDMUtYpZEKPqFKUpOMzK2",
  // };

  const url = apiUrl("pages/create.php");
  let result = null;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(pagesCreateData),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    result = await res.json();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the create pages was aborted");
    } else {
      return "Could not create new page";
    }
  }
  return result;
};
