// constants
import { SET_SCHEDULING_CONFIG } from "./context/PageContext";

// components
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Users from "./pages/users/Users";
import { Container, CssBaseline } from "@mui/material";
import TerbeauAppBar from "./components/TerbeauAppBar";
import TreeSlider from "./pages/tree/TreeSlider";
import Footer from "./components/Footer";
import Search from "./pages/search/Search";
import PasswordReset from "./pages/login/PasswordReset";
import Feedback from "./pages/feedback/Feedback";
import Providers from "./pages/providers/Providers";

// hooks
import { useAuthContext } from "./hooks/useAuthContext";
import { ThemeProvider } from "@emotion/react";
import { useThemeContext } from "./hooks/useThemeContext";
import { usePageContext } from "./hooks/usePageContext";
import { LicenseInfo } from "@mui/x-license-pro";
import { useEffect } from "react";

// functions
import { apiUrl } from "./functions/apiUrl";

// styles
import "./styles/childrens.css";

const userStatus = (user, selectedTreeId) => {
  // used for debugging. used to display user stats on the webpage
  return (
    <span>
      User:
      {`${user.terbeauUser?.name_last}${user.terbeauUser?.name_first ? ", " : ""}${user.terbeauUser?.name_first} `}
      Guest: {user.terbeauUser?.isGuest().toString()} isEditor: {user.terbeauUser?.isEditor(selectedTreeId).toString()}{" "}
      Admin: {user.terbeauUser?.isAdmin().toString()}
    </span>
  );
};

function App() {
  const { user, authIsReady } = useAuthContext();
  const { theme } = useThemeContext();
  const { dispatch, selectedTreeId } = usePageContext();

  // license information for DataGridPro -- Material UI project (purchased license never expires)
  LicenseInfo.setLicenseKey(
    "2116efe7baba38adeb6eef783725c44dTz03MjI2MixFPTE3MjI5NTA0MzQwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
  );

  // fetch config data
  useEffect(() => {
    const controller = new AbortController(); // allow interception of any API request
    const signal = controller.signal; // when we want to cancel the fetch request, we send a signal and the request is cancelled immediately

    // fetch the data and update states...
    const configUrl = apiUrl("shared/scheduling-config.php");

    fetch(configUrl, { signal, cache: "default" }) // signal from AbortController will cancel the request if cleanup function is activated by another request before the api call completes
      .then((res) => res.json())
      .then((data) => {
        data.sites = data.sites.map((s) => {
          return {
            sites_id: s.id,
            site: s.site,
            site_class: s.site_class,
            avatar_text: s.avatar_text,
            site_sort: s.sort,
          };
        });
        dispatch({ type: SET_SCHEDULING_CONFIG, payload: data });
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.error("cancelled");
        } else {
          //todo:handle error
        }
      });

    // clean up function
    return () => {
      controller.abort();
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {authIsReady && (
        <BrowserRouter>
          <Container>
            {false && user && userStatus(user, selectedTreeId)}
            <TerbeauAppBar />
            <Routes>
              <Route path="/" element={user ? <Dashboard /> : <Navigate to="/login" />} />
              <Route path="/users" element={user?.terbeauUser?.isAdmin() ? <Users /> : <Navigate to="/" />} />
              <Route path="/tree/:pages_id" element={selectedTreeId ? <TreeSlider /> : <Navigate to="/" />} />
              <Route path="/feedback" element={selectedTreeId ? <Feedback /> : <Navigate to="/" />} />
              <Route path="/providers" element={selectedTreeId ? <Providers /> : <Navigate to="/" />} />
              <Route path="/search" element={<Search />} />
              <Route path="/login" element={!user || user.terbeauUser?.isGuest() ? <Login /> : <Navigate to="/" />} />
              <Route
                path="/passwordreset"
                element={!user || user.terbeauUser?.isGuest() ? <PasswordReset /> : <Navigate to="/" />}
              />
              <Route path="/signup" element={!user || user.terbeauUser?.isAdmin() ? <Signup /> : <Navigate to="/" />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
          </Container>
        </BrowserRouter>
      )}
    </ThemeProvider>
  );
}

export default App;
