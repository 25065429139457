// constants
import { UPDATE_PAGES_PROVIDERS } from "../context/PageContext";

// components
import { DataGridPro } from "@mui/x-data-grid-pro";
import TerbeauMarkdown from "./TerbeauMarkdown";
import { Avatar, Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
import FormEditPagesProviderNotes from "./EditPagesProviderNotes";

// hooks
import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { usePageContext } from "../hooks/usePageContext";

// functions
import { updatePagesProviders } from "../functions/updatePagesProviders";

// styles
import "../styles/markdown.css";

export default function PageProviderDataGrid({ dataSet }) {
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openNotesEditor, setOpenNotesEditor] = useState(false);
  const [updatingSelection, setUpdatingSelection] = useState(false);
  const { user } = useAuthContext();
  const { dispatch, selectedPageId } = usePageContext();
  const [dataRows, setDataRows] = useState(dataSet.rows);

  const sortByRole = (a, b) => a - b;

  const data = {
    rows: dataRows,
    columns: [
      { field: "id", hide: true },
      {
        field: "avatar",
        headerName: "",
        editable: false,
        width: 40,
        renderCell: (params) => {
          return (
            <Avatar
              sx={{ width: 28, height: 28, filter: "drop-shadow(5px 5px 6px #000000)" }}
              alt={`${params.row.first} ${params.row.last}`}
              src={`/img/avatars/${params.row.avatar}`}
              className="provider-avatar"
            />
          );
        },
      },
      {
        field: "last",
        headerName: "Provider",
        editable: false,
        flex: 1,
        valueGetter: (params) => {
          return [params.row.last, params.row.first].join(", ");
        },
      },
      {
        field: "role_sort",
        headerName: "Role",
        editable: false,
        valueGetter: (params) => {
          return params.row.role_sort;
        },
        renderCell: (params) => {
          return params.row.role;
        },
        sortComparator: sortByRole,
      },

      {
        field: "notes",
        headerName: "Provider Notes",
        editable: false,
        flex: 2,
        renderCell: (params) => {
          return (
            <div className="page" style={{ display: "flex", alignItems: "center", paddingTop: "1rem" }}>
              <Button onClick={() => handleClickOpen(params.row)}>
                <Edit fontSize="small" color="action" />
              </Button>

              <TerbeauMarkdown>{params?.value}</TerbeauMarkdown>
            </div>
          );
        },
      },
    ],
    initialState: { rowSelectionModel: dataSet.selected, columns: { columnVisibilityModel: { id: false } } },
  };

  const hotUpdateDataGrid = (provider) => {
    // hot update the data set for the grid
    let newDataRows = [...dataRows];
    const providerIndex = newDataRows.findIndex((r) => r.id === provider.id);
    newDataRows.splice(providerIndex, 1, provider); // swap old version for new version
    setDataRows(newDataRows);
  };

  const [rowSelectionModel, setRowSelectionModel] = useState(data?.initialState?.rowSelectionModel);

  const handleRowSelection = async (newRowSelectionModel) => {
    if (updatingSelection) return; // prevent multiple clicks

    setUpdatingSelection(true);
    const lNew = newRowSelectionModel.length;
    const lOld = rowSelectionModel.length;
    let crud = "";
    let hotUpdateProvider = null;

    if (lNew === lOld) return; // no update has been requested

    if (lNew > lOld) {
      crud = "create";
      const addedIds = newRowSelectionModel.filter((f) => !rowSelectionModel.includes(f));
      hotUpdateProvider = data.rows.find((p) => p.id === addedIds[0]);
    } else {
      crud = "delete";
      const removedIds = rowSelectionModel.filter((f) => !newRowSelectionModel.includes(f));
      hotUpdateProvider = data.rows.find((p) => p.id === removedIds[0]);
    }

    // update backend and hot update
    const payload = {
      crud,
      user_id: user.terbeauUser.uid, // not implementing transaction log yet
      pages_id: selectedPageId,
      providers_id: hotUpdateProvider?.id,
      custom_sort_order: hotUpdateProvider?.custom_sort_order,
      notes: hotUpdateProvider?.notes,
    };

    const success = await updatePagesProviders(payload);
    if (success) {
      dispatch({
        type: UPDATE_PAGES_PROVIDERS,
        payload: { crud, provider: hotUpdateProvider },
      });
    }

    setRowSelectionModel(newRowSelectionModel);
    setUpdatingSelection(false);
  };

  useEffect(() => {}, [rowSelectionModel]);

  const handleClickOpen = (selectedRow) => {
    setSelectedProvider(selectedRow);
    setOpenNotesEditor(true);
  };

  const handleClose = () => {
    setSelectedProvider(null);
    setOpenNotesEditor(false);
  };

  return (
    data && (
      <>
        <DataGridPro
          sx={{
            // "& .MuiDataGrid-cellContent": {
            //   alignSelf: "flex-start",
            // },
            // "& span.MuiButtonBase-root.MuiCheckbox-root": {
            //   alignSelf: "flex-start",
            // },
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: "none", // hide the selectAll box at the top of the grid
            },
          }}
          getRowHeight={() => "auto"}
          // pagination
          disableRowSelectionOnClick
          checkboxSelection
          onRowSelectionModelChange={handleRowSelection}
          rowSelectionModel={rowSelectionModel}
          {...data}
        />
        {selectedProvider && (
          <FormEditPagesProviderNotes
            open={openNotesEditor}
            handleClose={handleClose}
            pagesProviderObj={selectedProvider}
            hotUpdateDataGrid={hotUpdateDataGrid}
          />
        )}
      </>
    )
  );
}
