// classes
import { TerbeauUser } from "../classes/TerbeauUser";

// hooks
import { createContext, useReducer, useEffect } from "react";
import { projectAuth } from "../firebase/config";

// functions
import { addTerbeauUser } from "../functions/addTerbeauUser";

// setting default user to guest.
// if implementing authorization for reps, then replace guest with null to force
// route to login screen.

const guest = new TerbeauUser("guest", "", "", "", [{ access: "guest", trees: ["all"] }]);
export const DEFAULT_USER = {
  terbeauUser: guest,
};

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: DEFAULT_USER };
    case "AUTH_IS_READY":
      return { user: action.payload, authIsReady: true };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: DEFAULT_USER,
    authIsReady: false,
  });

  useEffect(() => {
    const unsub = projectAuth.onAuthStateChanged(async (user) => {
      user = user ?? DEFAULT_USER;
      user = await addTerbeauUser(user);
      dispatch({ type: "AUTH_IS_READY", payload: user });
      unsub();
    });
  }, []);

  return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
