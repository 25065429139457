// constants
import { SET_SEARCH_STRING } from "../context/PageContext";
import { TOGGLE_THEME_MODE } from "../context/ThemeContext";

// components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TerbeauLogoWhite from "../assets/logo-terbeau-white.svg";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase, Typography, alpha, styled } from "@mui/material";

// hooks
import { useThemeContext } from "../hooks/useThemeContext";
import { usePageContext } from "../hooks/usePageContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TerbeauAppBar() {
  const { theme } = useThemeContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { logout, isPending } = useLogout();
  const { dispatch } = useThemeContext();
  const { dispatch: dispatchPageContext, searchString } = usePageContext();

  const handleSearchChange = (value) => {
    dispatchPageContext({ type: SET_SEARCH_STRING, payload: value });
  };

  const showSearchBar = location.pathname.match(/\/tree\//) || location.pathname.match(/\/search/);

  return (
    <AppBar position="static" sx={{ marginBottom: 2 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box>
            <Box
              component="img"
              mr={3}
              sx={{
                height: 28,
                filter: theme.palette.mode === "dark" ? "invert(.3)" : "invert(0)",
              }}
              alt="Terbeau logo"
              src={TerbeauLogoWhite}
            />
            <Typography variant="subtitle2">Advanced Decision Tree</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: { md: "flex" }, justifyContent: "flex-end" }}>
            {showSearchBar && (
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => handleSearchChange(e.target.value)}
                  value={searchString ?? ""}
                />
              </Search>
            )}
          </Box>

          <Box sx={{ flexGrow: 0, display: { sm: "flex" } }}>
            {location.pathname !== "/" && (
              <Button key={"home"} onClick={() => navigate(`/`)} sx={{ my: 2, color: "white", display: "block" }}>
                Home
              </Button>
            )}
            {user && user.terbeauUser?.isAdmin() && (
              <Button
                key={"manage-users"}
                onClick={() => navigate(`/users`)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Manage Users
              </Button>
            )}
            {(!user || user.terbeauUser?.isGuest()) && location.pathname !== "/login" && (
              <Button key={"login"} onClick={() => navigate(`/login`)} sx={{ my: 2, color: "white", display: "block" }}>
                Login
              </Button>
            )}

            {user.terbeauUser?.isAdmin() && (
              <Button
                key={"signup"}
                onClick={() => navigate(`/signup`)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Add User
              </Button>
            )}

            {user && !user.terbeauUser?.isGuest() && (
              <>
                {!isPending && (
                  <Button key={"logout"} onClick={logout} sx={{ my: 2, color: "white", display: "block" }}>
                    Logout {user.terbeauUser.name_first}
                  </Button>
                )}
                {isPending && (
                  <Button key={"pendingLogout"} sx={{ my: 2, color: "white", display: "block" }} disabled>
                    Logging out...
                  </Button>
                )}
              </>
            )}
            <IconButton
              sx={{
                ml: 1,
                marginTop: 1.5,
                marginBottom: 1.5,
              }}
              onClick={() => dispatch({ type: TOGGLE_THEME_MODE })}
              color="inherit"
            >
              {theme.palette.mode === "dark" ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TerbeauAppBar;
