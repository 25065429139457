// components
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GroupsIcon from "@mui/icons-material/Groups";
import TerbeauMarkdown from "./TerbeauMarkdown";
import FormEditPageProvidersGrid from "./EditPageProvidersGrid";
import ProviderSchedule from "./ProviderSchedule";

// hooks
import { useThemeContext } from "../hooks/useThemeContext";
import { useState } from "react";

// functions
import { notesTitle } from "../functions/notesTitle";
import { compareProviders } from "../functions/sortingFunctions";

// styles
import "./ProviderCard.css";

const InfoCell = ({ schedule }) => {
  return (
    <div className="info-cell">
      <ProviderSchedule schedule={schedule} editMode={false} />
    </div>
  );
};

const ProviderNotes = ({ provider }) => {
  const { theme } = useThemeContext();
  return (
    provider.notes && (
      <Box
        className="provider-notes page"
        style={{
          background:
            theme.palette.mode === "dark"
              ? "linear-gradient(135deg, rgba(47, 93, 117, 1) 0%, rgba(83, 47, 77, 1) 100%)"
              : "linear-gradient( 135deg, rgb(175, 212, 227) 0%, rgb(213, 169, 205) 100%)", // "#79acde"
        }}
      >
        <Typography variant="h6">{notesTitle(provider)}</Typography>
        <Typography className="markdown">
          <TerbeauMarkdown>{provider.notes}</TerbeauMarkdown>
        </Typography>
      </Box>
    )
  );
};

export default function ProviderCard({ providers, isEditing }) {
  const [openEditClinicianInfo, setOpenEditClinicianInfo] = useState(false);

  const handleCloseEditClinicanInfo = () => {
    setOpenEditClinicianInfo(false);
  };

  providers.sort(compareProviders);

  return (
    <div className="page-accordion" style={{ paddingLeft: "1rem", marginTop: "1rem" }}>
      {isEditing && (
        <Button variant="outlined" onClick={() => setOpenEditClinicianInfo(true)} sx={{ margin: "1rem 0" }}>
          Edit Clinician Information
        </Button>
      )}

      {providers?.length > 0 && (
        <Accordion
          defaultExpanded
          sx={{
            boxShadow:
              "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
            marginRight: "1rem",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Box
              sx={{
                margin: "-10px",
                padding: "4px 16px",
                width: "100%",
                backgroundColor: "#79acde",
                // borderRadius: "4px",
              }}
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <GroupsIcon color={"text"} />
              <Typography variant="h6">Clinician Information</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <div className="provider-card">
              {providers.map((p) => {
                return (
                  <div key={p.id} className={`provider-card ${p.role.replace(/\s+/g, "-").toLowerCase()}`}>
                    <div className="schedule">
                      <div className="avatar-paper"></div>
                      <div className="main-container">
                        <div className="provider-cell">
                          <Avatar
                            sx={{ width: 56, height: 56, filter: "drop-shadow(5px 5px 6px #000000)" }}
                            alt={`${p.first} ${p.last}`}
                            src={`/img/avatars/${p.avatar}`}
                            className="provider-avatar"
                          />
                          <Typography className="provider-nickname">{`${p.salutation}`}</Typography>
                          <Typography className="provider-name">{`${p.first} ${p.last}`}</Typography>
                          <Typography className="provider-role">{`${p.role}`}</Typography>
                        </div>
                        <InfoCell schedule={p.schedule} />
                      </div>
                    </div>
                    <ProviderNotes provider={p} />
                  </div>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      )}

      <FormEditPageProvidersGrid open={openEditClinicianInfo} handleClose={handleCloseEditClinicanInfo} />
    </div>
  );
}
