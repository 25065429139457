// constants
import { SET_SELECTED_PAGE_ID, SET_LAST_PAGE_UPDATE } from "../context/PageContext";
import { fields } from "../constants/constants";

// components
import { Box, Container, IconButton, Typography } from "@mui/material";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import DecisionCard from "./DecisionCard";
import FormCreatePages from "./CreateNewPage";
import FormUpdateFeedback from "./UpdateFeedback";
import ProviderCard from "./ProviderCard";
import FeedbackCards from "./FeedbackCards";
import RedirectPage from "./RedirectPage";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import PageTitle from "./PageTitle";

// hooks
import React, { useEffect, useState } from "react";
import { usePageContext } from "../hooks/usePageContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";

// functions
import { getPageUpdateText } from "../functions/getPageUpdateText";

//styles
import "../styles/childrens.css";
import "./MuiPage.css";

export default function MuiPage({ open, toggleLeftPanel }) {
  const { pageObjectReady } = usePageContext();

  return <div className="page">{pageObjectReady && <PageGrid handleDrawerOpen={toggleLeftPanel} open={open} />}</div>;
}

const PageGrid = ({ handleDrawerOpen, open }) => {
  const [openCreatePages, setOpenCreatePages] = useState(false);
  const [openUpdateFeedback, setOpenUpdateFeedback] = useState(false);
  const [defaultSummary, setDefaultSummary] = useState("");
  const [isEditor, setIsEditor] = useState(false);
  const {
    dispatch,
    editStatus,
    selectedTreeId,
    selectedPageId,
    currentPageObject,
    pageObjectReady,
    pagesIndexCollection,
    currentTree,
  } = usePageContext();
  const { user } = useAuthContext();

  const navigate = useNavigate();
  const redirect = currentPageObject.alternate_pages_id;
  const treeHasProviders = currentTree.providers_count > 0;

  const handleCloseCreatePages = () => {
    setOpenCreatePages(false);
  };

  const handleOpenUpdateFeedback = (feedback_id, default_summary = "") => {
    setDefaultSummary(default_summary);
    setOpenUpdateFeedback(feedback_id);
  };

  const handleCloseUpdateFeedback = () => {
    setDefaultSummary(null);
    setOpenUpdateFeedback(null);
  };

  useEffect(() => {
    if (user) setIsEditor(user.terbeauUser?.isEditor(selectedTreeId));
  }, [user, selectedTreeId]);

  useEffect(() => {
    // const fromNow = moment.utc(currentPageObject.timestamp).fromNow();
    const lastPageUpdate = getPageUpdateText(currentPageObject.timestamp);
    // ? `this page last updated ${fromNow}`
    // : `this page has not been updated`;
    dispatch({
      type: SET_LAST_PAGE_UPDATE,
      payload: lastPageUpdate,
    });
  }, [selectedPageId, dispatch, currentPageObject.timestamp]);

  // define hasActiveChildren constant to prevent any children from rendering if there are no active children for this page
  const findActiveChildren = currentPageObject?.children?.find((i) => i.active > 0);
  const hasActiveChildren = findActiveChildren !== undefined;

  return (
    pageObjectReady && (
      <Box pl={0} sx={{ padding: 0, overflow: "hidden" }}>
        <Container
          sx={{
            padding: 0,
            display: "flex",
            "& > *": {
              m: 0,
            },
          }}
        >
          <Box>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                handleDrawerOpen();
              }}
              edge="start"
              title={open ? "hide decision tree" : "show decision tree"}
              sx={{ ...{ opacity: "0.8" } }}
            >
              {open ? (
                <WysiwygIcon fontSize="large" color="primary" />
              ) : (
                <VerticalSplitIcon fontSize="large" color="primary" />
              )}
            </IconButton>
          </Box>

          <PageTitle breadcrumbs={currentPageObject.breadcrumbs} />
        </Container>

        <RedirectPage />

        <Box p={2}>
          <PageContents fields={fields} isEditor={isEditor} />
        </Box>
        {(hasActiveChildren && (currentPageObject?.children.length > 0) || (isEditor && editStatus && !redirect)) && (
          <ul className="children">
            {currentPageObject?.children?.map((child) => {
              const redirect = child.alternate_pages_id
                ? pagesIndexCollection.find((i) => i.id === parseInt(child.alternate_pages_id))?.page_title
                : "";
              return (
                // only render if the child is active
                child.active > 0 && (
                  <li
                    key={child.id}
                    onClick={() => {
                      dispatch({ type: SET_SELECTED_PAGE_ID, payload: child.id });
                      navigate(`/tree/${child.alternate_pages_id ?? child.id}`);
                    }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      title={redirect !== "" ? `redirects to ${redirect}` : null}
                    >
                      <Typography variant="subtitle1">{child.page_title}</Typography>
                      {child.alternate_pages_id && (
                        <SwapCallsIcon
                          sx={{ marginLeft: ".25rem", transform: "rotate(90deg)" }}
                          color="action"
                          fontSize={"sm"}
                        />
                      )}
                    </Box>
                  </li>
                )
              );
            })}
            {isEditor && editStatus && (
              <li key="add-child-node" className="add-child" onClick={() => setOpenCreatePages(true)}>
                <Typography variant="subtitle1">
                  <i>Create new child node</i>
                </Typography>
              </li>
            )}
          </ul>
        )}

        {!redirect && treeHasProviders && (
          <ProviderCard providers={currentPageObject.providers} isEditing={isEditor && editStatus} />
        )}
        <FeedbackCards
          feedback={currentPageObject.feedback}
          isEditor={isEditor}
          openEditor={handleOpenUpdateFeedback}
        />

        <FormCreatePages
          open={openCreatePages}
          handleClose={handleCloseCreatePages}
          trees_id={currentPageObject.trees_id}
          parent_id={currentPageObject.id}
          sort_order={(currentPageObject.children.length + 1) * 10}
        />
        <FormUpdateFeedback
          feedback_id={openUpdateFeedback}
          default_summary={defaultSummary}
          open={openUpdateFeedback}
          handleClose={handleCloseUpdateFeedback}
        />
      </Box>
    )
  );
};

const PageContents = ({ fields, isEditor }) => {
  const { currentPageObject, editStatus } = usePageContext();
  const redirect = currentPageObject.alternate_pages_id;
  let hasContent = false;
  fields.forEach((f) => (hasContent = hasContent || currentPageObject[f.id]));

  return (
    (hasContent || (isEditor && editStatus && !redirect)) && (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {fields?.map((field) => {
          return (
            currentPageObject &&
            (currentPageObject[field.id] || ((isEditor || field.guestEdit) && editStatus)) && (
              <DecisionCard
                key={fields.id}
                flexGrow={1}
                field={{ ...field, content: currentPageObject[field.id] }}
                isEditor={isEditor}
              />
            )
          );
        })}
      </Box>
    )
  );
};
