// constants

// components
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import Collapse from "@mui/material/Collapse";
import { Box, Button } from "@mui/material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";

// hooks
import { useState, useMemo, useEffect, useCallback } from "react";
import { useSpring, animated } from "@react-spring/web";
import { usePageContext } from "../hooks/usePageContext";
import { useNavigate } from "react-router-dom";

// styles
import "./TreeSelector.css";

// libraries
import moment from "moment";

// functions
import { convertPagesToTreeObj } from "../functions/convertPagesToTreeObj";

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

export default function TreeSelector({ pages: treePages, width: treeWidth }) {
  // check to see that the selectedTreeID matches the pages in the pageIndex Collection
  const { hideTreeSelector, pagesIndexCollection } = usePageContext();
  // treeObj is derived state and only used for the Tree Selector (removed from PageContext)
  const treeObj = useMemo(() => convertPagesToTreeObj(pagesIndexCollection), [pagesIndexCollection]);

  const allNodes = useMemo(() => treePages.map((t) => t.id.toString()), [treePages]);
  treeWidth = treeWidth ?? 360; // default tree width

  return (
    <div className={`tree-selector ${hideTreeSelector ? "hide" : ""}`}>
      {treeObj && <CustomizedTreeView data={treeObj} allNodes={allNodes} treeWidth={treeWidth} />}
    </div>
  );
}

const CustomizedTreeView = ({ data, allNodes, treeWidth }) => {
  const { currentTree, selectedPageId, pagesIndexCollection } = usePageContext();
  const [expanded, setExpanded] = useState([]);
  const today = new moment();
  const navigate = useNavigate();

  const StyledTreeItem = styled((props) => (
    <TreeItem
      {...props}
      // TransitionComponent={TransitionComponent}
      onClick={(e) => {
        navigate(`/tree/${props.itemId}`);
      }}
    />
  ))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 8,
      paddingLeft: 10,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    [`& .Mui-selected`]: {
      // color: "white",
      // backgroundColor: `${alpha(theme.palette.secondary.main, 0.8)}`,
      border: `1px solid var(--childrens-medium-blue)`,
    },
    [`& .Mui-selected:hover`]: {
      // color: `${alpha(theme.palette.text.primary, 1)}`,
      // backgroundColor: `${alpha(theme.palette.secondary.main, 0.8)}`,
    },
  }));

  const TreeContents = (data) => {
    return data.data.map((parent) => {
      const mTimestamp = moment.utc(parent.timestamp);
      const daysDiff = today.diff(mTimestamp, "days");

      let updated = "";
      if (parent.timestamp && daysDiff <= currentTree.updated_badge_days) {
        updated = "updated";
      }
      let redirect = "";
      if (parent.alternate_pages_id) {
        redirect = "redirect";
      }

      return (
        parent.active > 0 && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StyledTreeItem
              title={`page number ${parent.id.toString()}${
                parent.alternate_pages_id ? `; redirects to page no ${parent.alternate_pages_id}` : ""
              }`}
              itemId={parent.id.toString()}
              treesid={parent.trees_id.toString()}
              label={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <div className={`${[updated, redirect].join(" ").trim()}`}>{parent.page_title}</div>
                  {parent.alternate_pages_id && (
                    <SwapCallsIcon fontSize="small" color={"action"} sx={{ transform: "rotate(90deg)" }} />
                  )}
                </Box>
              }
              labelIcon={SupervisorAccountIcon}
              data-node-id={parent.id}
              data-parent_id={parent.parent_id}
              data-timestamp={parent.timestamp}
              key={parent.id}
            >
              {parent.children && <TreeContents data={parent.children} />}
            </StyledTreeItem>
          </Box>
        )
      );
    });
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? allNodes : []));
  };

  const parents = useCallback(
    (id) => {
      // finds parents recursively
      const node = pagesIndexCollection.find((i) => i.id === parseInt(id));
      if (node.parent_id < 1) {
        return [node.id.toString()];
      } else {
        return [...parents(node.parent_id), node.id.toString()];
      }
    },
    [pagesIndexCollection]
  );

  useEffect(() => {
    setExpanded(parents(selectedPageId));
  }, [selectedPageId, parents]);

  return (
    // check to see that the selectedTreeID matches the pages in the pageIndex Collection
    <>
      <Box sx={{ mb: 1 }}>
        <Button onClick={handleExpandClick}>{expanded.length === 0 ? "Expand all" : "Collapse all"}</Button>
      </Box>

      <SimpleTreeView
        aria-label="customized"
        expandedItems={expanded}
        onNodeToggle={handleToggle}
        selected={selectedPageId}
        slots={{
          expandIcon: AddBoxOutlinedIcon,
          collapseIcon: IndeterminateCheckBoxOutlinedIcon,
          endIcon: CircleOutlinedIcon,
        }}
      >
        <TreeContents data={data} />
      </SimpleTreeView>
    </>
  );
};
