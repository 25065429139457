// constants
import { UPDATE_REDIRECT } from "../context/PageContext";

// components
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import PageTitle from "./PageTitle";
import { Box, Typography } from "@mui/material";

// hooks
import { useEffect, useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { usePageContext } from "../hooks/usePageContext";

// functions
import { updatePages } from "../functions/updatePages";
import { pageBreadcrumbs } from "../functions/pageBreadcrumbs";

const UPDATE = "UPDATE";
const REMOVE = "REMOVE";

export default function FormUpdateRedirect({ open, handleClose }) {
  const [alternatePagesId, setAlternatePagesId] = useState("");
  const { selectedPageId, currentPageObject, pagesIndexCollection } = usePageContext();
  const { user } = useAuthContext();
  const { dispatch } = usePageContext();
  const [updating, setUpdating] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const idExists = pagesIndexCollection.findIndex((p) => p.id === parseInt(alternatePagesId)) > -1;
    if (idExists & (parseInt(alternatePagesId) !== parseInt(currentPageObject.alternate_pages_id))) {
      setIsValid(true);
      setBreadcrumbs(pageBreadcrumbs(pagesIndexCollection, parseInt(alternatePagesId)));
    } else {
      setIsValid(false);
      setBreadcrumbs([]);
    }
  }, [alternatePagesId, pagesIndexCollection, currentPageObject.alternate_pages_id]);

  const handleUpdate = async (type) => {
    if (![UPDATE, REMOVE].includes(type)) return;

    if (!isValid) return;

    // update database with new version
    setUpdating(true);
    const payload = {
      id: selectedPageId,
      user_id: user.uid,
      timestamp: new Date().getTime(),
      field: "alternate_pages_id",
      old_value: currentPageObject.alternate_pages_id,
      new_value: type === UPDATE ? alternatePagesId : null,
    };

    const success = await updatePages(payload); // returns true if update succeeded

    if (success) {
      dispatch({
        type: UPDATE_REDIRECT,
        alternatePagesId,
      });
    } else {
      console.error("an error occurred in updating the redirect.");
    }
    setUpdating(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Redirect to Alternate Page</DialogTitle>
        <Typography variant="subtitle2" sx={{ padding: "0rem 24px", marginTop: "-1rem" }}>
          (hover over page title in tree navigator to discover page numbers)
        </Typography>
        <DialogContent>
          <Box sx={{ display: "flex", padding: "0 2rem", marginBottom: "1rem" }}>
            <TextField
              autoFocus
              margin="dense"
              id="redirect"
              label="Redirect to page number"
              type="number"
              variant="standard"
              onChange={(e) => setAlternatePagesId(e.target.value)}
              value={alternatePagesId}
            />
          </Box>
          {isValid && (
            <Box
              sx={{
                borderRadius: "1rem",
                padding: "1rem .5rem",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
              }}
            >
              <PageTitle breadcrumbs={breadcrumbs} allowEdit={false} linkBreadcrumbs={false} showControls={false} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {currentPageObject.alternate_pages_id && (
            <Button onClick={() => handleUpdate(REMOVE)} disabled={!currentPageObject.alternate_pages_id}>
              Remove Current Redirect
            </Button>
          )}
          <LoadingButton
            loading={updating}
            variant="outlined"
            loadingPosition="start"
            startIcon={<Add />}
            onClick={() => handleUpdate(UPDATE)}
            disabled={!isValid}
          >
            <span>Update Redirect</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
