// constants
import { UPDATE_PAGES_PROVIDERS } from "../context/PageContext";

// components
import TerbeauMarkdownEditor from "./TerbeauMarkdownEditor";

// hooks
import { useState } from "react";
import { usePageContext } from "../hooks/usePageContext";
import { useAuthContext } from "../hooks/useAuthContext";

// functions
import { updatePagesProviders } from "../functions/updatePagesProviders";
import { notesTitle } from "../functions/notesTitle";

export default function FormEditPagesProviderNotes({ open, handleClose, pagesProviderObj, hotUpdateDataGrid }) {
  const { dispatch, selectedPageId: pages_id } = usePageContext();
  const { user } = useAuthContext();

  const [updating, setUpdating] = useState(false);

  const handleUpdate = async (editFieldContent) => {
    const crud = "update"; // editing notes always occurs on an existing record

    if (editFieldContent !== pagesProviderObj.notes) {
      // update database with new version
      setUpdating(true);

      const { id: providers_id, custom_sort_order } = pagesProviderObj;
      const payload = {
        crud,
        user_id: user.terbeauUser.uid, // not implementing transaction log yet
        pages_id,
        providers_id,
        custom_sort_order,
        notes: editFieldContent,
      };

      const success = await updatePagesProviders(payload);
      if (success) {
        dispatch({
          type: UPDATE_PAGES_PROVIDERS,
          payload: { crud, provider: { ...pagesProviderObj, notes: editFieldContent } },
        });
        hotUpdateDataGrid({ ...pagesProviderObj, notes: editFieldContent });
      }
      setUpdating(false);
      handleClose();

      return;
    }

    setUpdating(false);
    handleClose();
  };

  return (
    <TerbeauMarkdownEditor
      handleUpdate={handleUpdate}
      handleClose={handleClose}
      open={open}
      title={notesTitle(pagesProviderObj)}
      revertValue={pagesProviderObj.notes}
      updating={updating}
    />
  );
}
