// functions
import { apiUrl } from "./apiUrl";

export const updatePagesProviders = async (pagesProvidersData) => {
  // use the following object format for pagesProvidersData:
  // const testData = {
  //     crud: { create | update | delete },
  //     pages_id: 2111,
  //     providers_id: 1,
  //     custom_sort_order: null,
  //     notes: 'Test note'
  // }

  const url = apiUrl("providers/pages-providers-update.php");
  let result = null;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(pagesProvidersData),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    result = await res.text();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the update pages was aborted");
      return false;
    } else {
      console.error("Could not update page information");
      return false;
    }
  }
  return result.slice(1) === "success";
};
