// components
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FeedbackRounded } from "@mui/icons-material";

// hooks
import { usePageContext } from "../hooks/usePageContext";
import { useThemeContext } from "../hooks/useThemeContext";

// styles
import "./FeedbackCards.css";

// libraries
import moment from "moment/moment";

export default function FeedbackCards({ feedback, isEditor, openEditor }) {
  const { theme } = useThemeContext();
  const { editStatus } = usePageContext();

  if (feedback?.length < 1) return; // return null element if the providers array is empty or doesn't exist

  return (
    <div className="page-accordion" style={{ paddingLeft: "1rem", marginTop: "2.5rem", marginBottom: "1rem" }}>
      <Accordion
        defaultExpanded={false}
        sx={{
          boxShadow:
            "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
          marginRight: "1rem",
          marginBottom: "1rem",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              margin: "-10px",
              padding: "4px 16px",
              width: "100%",
              backgroundColor: "#aaa",
              // borderRadius: "4px",
            }}
            display={"flex"}
            alignItems={"center"}
            gap={1}
          >
            <FeedbackRounded color={"text"} />
            <Typography variant="h6">Review Feedback</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ marginRight: "1rem" }}>
          <div className="feedback-cards-container">
            {feedback?.map((f) => {
              return (
                <div key={f.id} className="feedback-card">
                  <div className="feedback-grid">
                    <div className="feedback-demographics">
                      <p className="ago">{moment(f.submit_timestamp).fromNow()}</p>
                      <p className="submitted-by">
                        {f.submitted_by} <br />
                        {moment(f.submit_timestamp).format("MMM D, YYYY")}
                      </p>
                    </div>
                    <div className="feedback-narrative">
                      <div>
                        <Typography fontSize={".8rem"} mb={0} variant="h6" color="secondary">
                          Feedback
                        </Typography>
                        <Typography>{f.suggestion}</Typography>
                      </div>
                      {f.updated === 0 && isEditor && editStatus && (
                        <Button variant="outlined" onClick={() => openEditor(f.id)}>
                          Create Update Summary
                        </Button>
                      )}
                    </div>
                  </div>
                  {f.updated !== 0 && (
                    <>
                      <Box
                        sx={{
                          backgroundColor:
                            theme.palette.mode === "dark"
                              ? "var(--childrens-field-darker-heading-color)"
                              : "var(--childrens-light-blue)",
                        }}
                        className="feedback-grid"
                      >
                        <div className="feedback-demographics">
                          <p className="ago">{moment(f.update_timestamp).fromNow()}</p>
                          <p className="submitted-by">
                            {f.updated_by} <br />
                            {moment(f.update_timestamp).format("MMM D, YYYY")}
                          </p>
                        </div>

                        <div className="feedback-narrative">
                          <div>
                            <Typography fontSize={".8rem"} mb={0} variant="h6" color="secondary">
                              Update Summary
                            </Typography>
                            <Typography>{f.update_summary}</Typography>
                          </div>
                          {f.updated !== 0 && isEditor && editStatus && (
                            <Button variant="outlined" onClick={() => openEditor(f.id, f.update_summary)}>
                              Edit Summary
                            </Button>
                          )}
                        </div>
                      </Box>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
