// constants
import { HOT_UPDATE_FEEDBACK } from "../context/PageContext";

// components
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";
import { Typography } from "@mui/material";

// hooks
import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { usePageContext } from "../hooks/usePageContext";

// functions
import { timestampNowSQL } from "../functions/timestampNowSQL";
import { createFeedback } from "../functions/createFeedback";

export default function FormCreateFeedback({ open, handleClose, pages_id }) {
  const [suggestion, setSuggestion] = useState("");
  const [username, setUsername] = useState("");
  const { user } = useAuthContext();
  const { selectedTreeId, dispatch } = usePageContext();
  const [creating, setCreating] = useState(false);
  const [updateStatus, setUpdateStatus] = useState("");

  const isEditor = user?.terbeauUser?.isEditor(selectedTreeId);

  const validSubmission = () => {
    const valid = suggestion.trim()?.length > 3 && (isEditor || username.trim()?.length > 2);
    return valid;
  };

  const handleCreate = async () => {
    if (!validSubmission()) return;

    const timestamp = timestampNowSQL();
    // update database with new version
    setCreating(true);
    const payload = {
      submit_timestamp: timestamp,
      suggestion,
      pages_id,
      submitted_by_name: username === "" ? null : username,
      submitted_by_uid: user?.terbeauUser?.uid === "guest" ? null : user.terbeauUser.uid,
    };

    const res = await createFeedback(payload);

    setCreating(false);
    if (!res.feedback_id) {
      setUpdateStatus("Your feedback was NOT saved. Please try again or contact your administrator.");
    } else {
      payload.id = res.feedback_id; // assign newly created id before hot update
      dispatch({ type: HOT_UPDATE_FEEDBACK, action: payload });
      setUpdateStatus("Your feedback has been submitted!");
    }

    setTimeout(() => {
      setUpdateStatus("");
      setSuggestion("");
      handleClose();
    }, 3000);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Submit Feedback</DialogTitle>
        <DialogContent>
          {!updateStatus && (
            <>
              <DialogContentText>Describe your suggestion to improve this page</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="suggestion"
                label="Suggestion"
                type="text"
                fullWidth
                multiline
                // required
                variant="outlined"
                onChange={(e) => setSuggestion(e.target.value)}
                value={suggestion}
              />
              {!isEditor && (
                <TextField
                  margin="dense"
                  id="username"
                  label="Your name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
              )}
            </>
          )}
          {updateStatus && <Typography>{updateStatus}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{updateStatus ? "Close" : "Cancel"}</Button>
          {!updateStatus && (
            <LoadingButton
              loading={creating}
              variant="outlined"
              loadingPosition="start"
              startIcon={<Add />}
              onClick={handleCreate}
              // disabled={!validSuggestion(suggestion.trim())}
              disabled={!validSubmission()}
            >
              <span>Submit Suggestion</span>
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
