// components
import PageProviderDataGrid from "./PageProviderDataGrid";
import { Box, CircularProgress } from "@mui/material";

// hooks
import { useEffect, useState } from "react";

// functions
import { apiUrl } from "../functions/apiUrl";

export default function PageProviderMenu({ pages_id }) {
  const [providerPageData, setProviderPageData] = useState(null);
  // fetch menu data

  const getSelectedProviders = (data) => {
    const selectedProviders = data.map((p) => (p.provider_crud === "active" ? p.id : null));
    return selectedProviders;
  };

  useEffect(() => {
    const controller = new AbortController(); // allow interception of any API request
    const signal = controller.signal; // when we want to cancel the fetch request, we send a signal and the request is cancelled immediately
    // fetch the data and update states...
    const pagesUrl = apiUrl("providers/menu.php", {
      pages_id,
    });

    fetch(pagesUrl, { signal, cache: "reload" }) // signal from AbortController will cancel the request if cleanup function is activated by another request before the api call completes
      .then((res) => res.json())
      .then((data) => {
        const gridData = {};
        gridData.selected = getSelectedProviders(data);
        gridData.rows = data;
        setProviderPageData(gridData);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.error("cancelled");
        } else {
          //todo:handle error
        }
      });

    // clean up function
    return () => {
      controller.abort();
    };
  }, [pages_id]);

  return (
    <>
      {!providerPageData && (
        <Box
          sx={{ display: "flex", width: "100%", minHeight: "400px", alignItems: "center", justifyContent: "center" }}
        >
          <CircularProgress />
        </Box>
      )}
      {providerPageData && <PageProviderDataGrid dataSet={providerPageData} />}
    </>
  );
}
