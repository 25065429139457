import React, { useEffect, useState } from "react";

import { apiUrl } from "../functions/apiUrl";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Box, Container } from "@mui/system";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useThemeContext } from "../hooks/useThemeContext";
import FormEditProvider from "./EditProvider";

export default function GetProvider({ open, handleClose, trees_id, exclude, reason, allowCreate = false }) {
  const { theme } = useThemeContext();
  const [rows, setRows] = useState(null);
  const [openCreateProvider, setOpenCreateProvider] = useState(false);

  const handleProviderSelect = (provider) => {
    handleClose(provider);
  };

  const handleCloseCreateProvider = (requireConfirm, provider) => {
    setOpenCreateProvider(false);
    handleClose(provider, requireConfirm); // (providerObj, bypassConfirm <bool>)
  };

  const columns = [
    {
      field: "id",
      headerName: "Edit",
      editable: false,
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <Button
            variant="outlined"
            sx={{ justifyContent: "flex-start" }}
            onClick={() => handleProviderSelect(params.row)}
          >
            Select
          </Button>
        );
      },
    },
    {
      field: "avatar",
      headerName: "",
      editable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <Avatar
            sx={{
              opacity: `${params.row.active === 1 ? 1 : 0.5}`,
              width: 28,
              height: 28,
              margin: "1rem 0",
              filter: "drop-shadow(5px 5px 6px #000000)",
            }}
            alt={`${params.row.first} ${params.row.last}`}
            src={`/img/avatars/${params.row.avatar}`}
            className="provider-avatar"
          />
        );
      },
    },
    {
      field: "last",
      headerName: "Provider",
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        return [[params.row.last, params.row.credentials].join(" "), params.row.first].join(", ");
      },
    },
    {
      field: "role_sort",
      headerName: "Role",
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        return params.row.role_sort;
      },
      renderCell: (params) => {
        return params.row.role;
      },
      sortComparator: (a, b) => a - b,
    },
  ];

  useEffect(() => {
    // rerun with every render
    const controller = new AbortController(); // allow interception of any API request
    const signal = controller.signal; // when we want to cancel the fetch request, we send a signal and the request is cancelled immediately
    // fetch the data and update states...
    const pagesUrl = apiUrl("providers/tree-providers.php", { trees_id, exclude: exclude ? 1 : 0 });
    fetch(pagesUrl, { signal, cache: "reload" }) // signal from AbortController will cancel the request if cleanup function is activated by another request before the api call completes
      .then((res) => res.json())
      .then((data) => {
        setRows(data);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.error("cancelled");
        } else {
          //todo:handle error
        }
      });

    // clean up function
    return () => {
      controller.abort();
    };
  }, [open, trees_id, exclude]); // including open causes the menu data to get fetched every time the dialog opens

  return (
    <>
      <Dialog open={open} onClose={() => handleClose(null)} fullWidth maxWidth={"md"}>
        <DialogTitle>Choose a Provider {reason}</DialogTitle>
        <Container sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: "1", flexBasis: "50%" }}>
            <DialogContent>
              <Box
                sx={{
                  width: "100%",
                  "& .active": {},
                  "& .inactive": {
                    backgroundColor: theme.palette.mode === "dark" ? "#373737" : "#ddd", // "#79acde"
                    color: "#666",
                    fontStyle: "italic",
                  },
                }}
              >
                <DataGridPro
                  columns={columns}
                  rows={rows}
                  getCellClassName={(params) => (params.row.active === 1 ? "active" : "inactive")}
                />
              </Box>
            </DialogContent>
          </Box>
        </Container>
        <DialogActions>
          {allowCreate && <Button onClick={() => setOpenCreateProvider(true)}>Create New Provider</Button>}
          <Button onClick={() => handleClose(null)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <FormEditProvider createOrUpdate={"create"} open={openCreateProvider} handleClose={handleCloseCreateProvider} />
    </>
  );
}
