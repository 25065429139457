// constants
import { ACCESS } from "../constants/constants";

// components
import { Container, FormControlLabel, Switch } from "@mui/material";
import { RadioButton } from "./RadioButton";

// hooks

// functions
import { getTreeState } from "../functions/editUserFormReducer";

// libraries
import { DataGridPro } from "@mui/x-data-grid-pro";
import { toTitleCase } from "../functions/toTitleCase";

export default function UserPrivileges({ dispatch, state, trees }) {
  const idAdmin = ACCESS.find((a) => a.access === "admin").id;

  const columns = [{ field: "tree", headerName: "Tree", width: 200 }];

  ACCESS.forEach((a) => {
    if (a.access !== "admin")
      columns.push({
        field: a.access,
        headerName: toTitleCase(a.access),
        renderCell: (params) => {
          return (
            <RadioButton
              changed={() =>
                dispatch({
                  type: "CHANGE_TREE_ACCESS",
                  payload: { id: params.row.id, access: a.access },
                })
              }
              id={`${params.row.id}.${a.id}`}
              // isSelected={privilegeENT === a.access}
              isSelected={getTreeState(state, params.row.id) === a.access}
              label={toTitleCase(a.access)}
              value={a.access}
            />
          );
        },
        width: 200,
      });
  });

  const rows = [];
  trees.forEach((t) => {
    let newRow = { id: t.id, tree: t.name };
    ACCESS.forEach((a) => {
      if (a.access === "admin") return; // skip this iteration
      newRow[a.access] = getTreeState(state, t.id) === a.access;
    });
    rows.push(newRow);
  });

  return (
    <Container>
      <FormControlLabel
        value="admin"
        control={
          <Switch
            color="primary"
            checked={getTreeState(state, idAdmin) === "Yes"}
            onChange={() =>
              dispatch({
                type: "CHANGE_TREE_ACCESS",
                payload: { id: idAdmin, access: getTreeState(state, idAdmin) === "Yes" ? "No" : "Yes" },
              })
            }
            // onChange={() => setData({ ...data, active: Math.abs(data.active - 1) })}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={"Admin"}
        labelPlacement="end"
      />

      <DataGridPro columns={columns} rows={rows} columnHeaderHeight={0} hideFooter />
    </Container>
  );
}
