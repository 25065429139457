// components
import CircularIndeterminate from "./CircularIndeterminate";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import { Link } from "react-router-dom";

// hooks
import { useCallback } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

// functions
import { apiUrl } from "../functions/apiUrl";
import { updateFeedback } from "../functions/updateFeedback";
import { timestampNowSQL } from "../functions/timestampNowSQL";

// libraries
import moment from "moment/moment";

const useUpdateFeedback = () => {
  const { user } = useAuthContext();
  return useCallback(
    (row) =>
      new Promise(async (resolve, reject) => {
        const timestamp = timestampNowSQL();
        if (row.update_summary.trim() === "") {
          reject();
        } else {
          const success = await updateFeedback({
            id: row.id,
            updated_by_uid: user.uid,
            update_summary: row.update_summary,
            update_timestamp: timestamp,
          });
          if (success) resolve(row);
        }
      }),
    [user.uid]
  );
};

function computeMutation(newRow, oldRow) {
  if (newRow.update_summary !== oldRow.update_summary) {
    return `save update summary as '${newRow.update_summary}'`;
  }
  return null;
}

export default function FeedbackDataGrid({ urlOptions, isEditor }) {
  const mutateRow = useUpdateFeedback();
  const noButtonRef = useRef(null);
  const [promiseArguments, setPromiseArguments] = useState(null);
  const [feedbackSorted, setFeedbackSorted] = useState(null);
  const [feedbackIsPending, setFeedbackIsPending] = useState(false);
  const [feedbackError, setFeedbackError] = useState(null);

  const [snackbar, setSnackbar] = useState(null);

  // fetch feedback data

  useEffect(() => {
    if (!urlOptions) return;
    setFeedbackIsPending(true);
    setFeedbackError(null);

    const controller = new AbortController(); // allow interception of any API request
    const signal = controller.signal; // when we want to cancel the fetch request, we send a signal and the request is cancelled immediately

    // fetch the data and update states...
    const feedbackUrl = apiUrl("feedback/read.php", urlOptions);

    fetch(feedbackUrl, { signal, cache: "reload" }) // signal from AbortController will cancel the request if cleanup function is activated by another request before the api call completes
      .then((res) => res.json())
      .then((data) => {
        setFeedbackSorted([...data].sort((a, b) => (a.submit_timestamp > b.submit_timestamp ? -1 : 1)));
        setFeedbackIsPending(false);
        setFeedbackError(null);
      })
      .catch((err) => {
        setFeedbackIsPending(false);
        setFeedbackError(err);
        setFeedbackSorted(null);
        if (err.name === "AbortError") {
          console.error("cancelled");
        } else {
          //todo:handle error
        }
      });

    // clean up function
    return () => {
      controller.abort();
    };
  }, [urlOptions]);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          //Save the arguments to resolve or reject the promise later
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          resolve(oldRow); // Nothing was changed
        }
      }),
    []
  );

  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments;
    resolve(oldRow); // Resolve with the old row to no update the internal state
    setPromiseArguments(null);
  };

  const handleYes = async () => {
    const { newRow, oldRow, reject, resolve } = promiseArguments; // these were saved in processRowUpdate

    try {
      // Make the HTTP request to save in the backend database
      const response = await mutateRow(newRow);
      setSnackbar({ children: "Update summary saved", severity: "success" });
      resolve(response);
      setPromiseArguments(null);
    } catch (error) {
      setSnackbar({ children: "Cannot delete previous update summary", severity: "error" });
      reject(oldRow);
      setPromiseArguments(null);
    }
  };

  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }

    const { newRow, oldRow } = promiseArguments;
    const mutation = computeMutation(newRow, oldRow);

    return (
      <Dialog maxWidth="md" open={!!promiseArguments}>
        <DialogTitle>Update Summary?</DialogTitle>
        <DialogContent dividers>{`Pressing 'Yes' will ${mutation}.`}</DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Yes</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns = [
    {
      type: "string",
      field: "submit_timestamp",
      headerName: "Submitted",
      width: 150,
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return (
          <div>
            {moment(params.value).fromNow()}
            <br />
            {moment(params.value).format("ddd, MMM D, YYYY")}
          </div>
        );
      },
    },
    {
      type: "string",
      field: "submitted_by",
      headerName: "Submitted by",
      width: 150,
      valueGetter: (params) => {
        return params.value;
      },
      valueFormatter: (params) => {
        return params.value;
      },
    },
    {
      type: "string",
      field: "page_title",
      headerName: "Page",
      width: 150,
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value ? <Link to={`/tree/${params.row.pages_id}`}>{params.value}</Link> : "";
      },
    },
    {
      type: "string",
      field: "suggestion",
      headerName: "Suggestion",
      width: 250,
      valueGetter: (params) => {
        return params.value;
      },
      valueFormatter: (params) => {
        return params.value;
      },
    },
    {
      type: "string",
      field: "update_summary",
      headerName: "Update Summary",
      width: 250,
      editable: isEditor,
      valueGetter: (params) => {
        return params.value;
      },
      valueFormatter: (params) => {
        return params.value;
      },
    },
    {
      type: "string",
      field: "updated_by",
      headerName: "Updated by",
      width: 150,
      valueGetter: (params) => {
        return params.value;
      },
      valueFormatter: (params) => {
        return params.value;
      },
    },
    {
      type: "string",
      field: "update_timestamp",
      headerName: "Updated",
      width: 180,
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        const diff = moment(params.value).diff(moment(params.row.submit_timestamp), "days");
        return params.value ? (
          <div>
            {Math.abs(diff) + " days " + (diff > 0 ? "after" : "before") + " submission"}
            <br />
            {moment(params.value).format("ddd, MMM D, YYYY")}
          </div>
        ) : (
          ""
        );
      },
    },
  ];

  return (
    <div>
      {feedbackError && <p className="error">{feedbackError}</p>}
      {feedbackIsPending && <CircularIndeterminate />}
      {renderConfirmDialog()}
      {feedbackSorted && (
        <DataGridPro
          columns={columns}
          rows={feedbackSorted}
          processRowUpdate={processRowUpdate}
          pagination={true}
          getRowHeight={() => "auto"}
          pageSizeOptions={[5, 10, 20]}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5, page: 0 },
            },
          }}
        />
      )}
      {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </div>
  );
}
