// components
import ProjectList from "../../components/ProjectList";
import { Typography } from "@mui/material";

// hooks
import { useFetch } from "../../hooks/useFetch";

// functions
import { apiUrl } from "../../functions/apiUrl";

// styles
import "./Dashboard.css";

export default function Dashboard() {
  const treeUrl = apiUrl("trees/read.php");
  const { data: trees, error: treeDataError } = useFetch(treeUrl);

  return (
    <div>
      {treeDataError && <p className="error">{treeDataError}</p>}
      {!trees && <Typography>Loading your trees...</Typography>}
      {trees && <ProjectList trees={trees} />}
    </div>
  );
}
