// functions
import { apiUrl } from "./apiUrl";
import { timestampNowSQL } from "./timestampNowSQL";

export const updateFeedback = async (feedbackUpdateData) => {
  // use the following object format for feedbackUpdateData:
  // const sampleData = {
  //   id: 1,
  //   updated_by_uid: "MnWVregrDMUtYpZEKPqFKUpOMzK2",
  //   update_summary: "This is a test summary from postman.",
  //   update_timestamp: "2023-08-06 16:10:00",
  // };

  // if timestamp is empty, insert current timestamp
  feedbackUpdateData.update_timestamp = feedbackUpdateData.update_timestamp ?? timestampNowSQL();

  const url = apiUrl("feedback/update.php");
  let result = null;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(feedbackUpdateData),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    result = await res.text();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the update feedback was aborted");
      return false;
    } else {
      console.error("Could not update feedback information");
      return false;
    }
  }
  // if successful, result = "\nsuccess";
  return result.slice(1) === "success";
};
