// functions
import { apiUrl } from "./apiUrl";

export const getSqlUser = async (terbeauUserUid) => {
  const url = apiUrl("users/read.php", { id: terbeauUserUid });
  let json = {};

  try {
    const res = await fetch(url, {
      method: "GET",
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    json = await res.json();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the getSqlUser was aborted");
    } else {
      return "Could not getSqlUser";
    }
  }

  const user = json[0];

  return user;
};
