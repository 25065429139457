// constants
import { UPDATE_PAGE_OBJ } from "../context/PageContext";

// components
import TerbeauMarkdownEditor from "./TerbeauMarkdownEditor";

// hooks
import { useState } from "react";
import { usePageContext } from "../hooks/usePageContext";
import { useAuthContext } from "../hooks/useAuthContext";

// functions
import { updatePages } from "../functions/updatePages";

export default function FormEditPageContainer({ open, handleClose, field }) {
  const { dispatch, selectedPageId, currentPageObject } = usePageContext();
  const { user } = useAuthContext();

  const [updating, setUpdating] = useState(false);

  const handleUpdate = async (editFieldContent) => {
    if (editFieldContent !== currentPageObject[field.id]) {
      // update database with new version
      setUpdating(true);

      const payload = {
        id: selectedPageId,
        user_id: user.terbeauUser.uid,
        timestamp: new Date().getTime(),
        field: field.id,
        old_value: currentPageObject[field.id],
        new_value: editFieldContent,
      };

      const success = await updatePages(payload);
      if (success) {
        dispatch({
          type: UPDATE_PAGE_OBJ,
          payload: { ...currentPageObject, [field.id]: editFieldContent, timestamp: payload.timestamp },
        });
      }
      setUpdating(false);
      handleClose();

      return;
    }

    setUpdating(false);
    handleClose();
  };

  return (
    <TerbeauMarkdownEditor
      handleUpdate={handleUpdate}
      handleClose={handleClose}
      open={open}
      title={field.title}
      revertValue={currentPageObject[field.id]}
      updating={updating}
    />
  );
}
