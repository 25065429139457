// functions
import { apiUrl } from "./apiUrl";

export const postAPI = async ({ api, payload, apiOptions = null }) => {
  let result = null;

  const url = apiUrl(api, apiOptions);

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    result = await res.text();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error(`the ${api} api was aborted`);
    } else {
      console.error(`Could not complete ${api}`);
      return `Could not complete ${api}`;
    }
  }
  return result.slice(1) === "success";
};
