// constants

// components
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/material";
import PageProviderMenu from "./PageProviderMenu";

// hooks
import { usePageContext } from "../hooks/usePageContext";

// functions

export default function FormEditPageProvidersGrid({ open, handleClose }) {
  const { currentPageObject } = usePageContext();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
      <DialogTitle>Edit Clinician Information for {currentPageObject.page_title}</DialogTitle>
      <Container sx={{ display: "flex" }}>
        <PageProviderMenu pages_id={currentPageObject.id} />
      </Container>
      <DialogActions>
        <Button onClick={handleClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
}
