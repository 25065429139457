// constants
import { ENV, PRODUCTION_PATH } from "../constants/constants";

// components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box, Button, CardActionArea } from "@mui/material";

export default function TreeCard({ tree, onClick, isEditor }) {
  return (
    <Card
      sx={{
        maxWidth: 345,
        border: tree.env === ENV.development ? "2px solid #DF4661" : "",
        opacity: tree.env === ENV.development ? 0.75 : 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <CardActionArea onClick={() => onClick(tree)}>
        <CardMedia component="img" height="140" image={`${PRODUCTION_PATH}/img/${tree.avatar}`} alt={tree.name} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {tree.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {tree.description}
          </Typography>
          {tree.env === ENV.development && (
            <Typography variant="subtitle1" color="error">
              in development
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      {isEditor && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => onClick(tree, { option: "feedback" })}>Review Feedback</Button>
          <Button onClick={() => onClick(tree, { option: "providers" })}>Manage Providers</Button>
        </Box>
      )}
    </Card>
  );
}
