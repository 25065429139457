// componenents
import { Box, Button, Typography } from "@mui/material";
import PageTitle from "./PageTitle";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import Grid from "@mui/material/Unstable_Grid2";
import { Edit } from "@mui/icons-material";
import FormUpdateRedirect from "./EditRedirect";

// hooks
import { usePageContext } from "../hooks/usePageContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useState } from "react";

// functions
import { pageBreadcrumbs } from "../functions/pageBreadcrumbs";

export default function RedirectPage() {
  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);
  const { currentPageObject, pagesIndexCollection, editStatus, currentTree } = usePageContext();
  const { user } = useAuthContext();
  const breadcrumbs = pageBreadcrumbs(pagesIndexCollection, currentPageObject.alternate_pages_id);
  const isEditing = editStatus && user.terbeauUser?.isEditor(currentTree);
  const redirect = currentPageObject.alternate_pages_id;

  const handleCloseRedirectDialog = () => {
    setOpenRedirectDialog(false);
  };

  return (
    redirect && (
      <>
        <Box
          sx={{
            borderRadius: ".5rem",
            width: "80%",
            padding: "1rem",
            marginTop: "2rem",
            marginLeft: "4rem",
            border: "2px solid var(--childrens-medium-blue)",
            boxShadow: "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
          }}
        >
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid xs={2} display="flex" justifyContent="center" alignItems="center">
              <SwapCallsIcon color="primary" sx={{ transform: "rotate(-90deg)", fontSize: 56 }} />
            </Grid>
            <Grid xs>
              <Typography variant="subtitle1" color="gray" pl={4}>
                This page redirects (no. {currentPageObject.alternate_pages_id})
              </Typography>
              <PageTitle breadcrumbs={breadcrumbs} showControls={false} linkTitle={true} />
            </Grid>
            {isEditing && (
              <Grid>
                <Button onClick={() => setOpenRedirectDialog(true)} sx={{ justifyContent: "right" }}>
                  <Edit fontSize="small" color="action" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <FormUpdateRedirect open={openRedirectDialog} handleClose={handleCloseRedirectDialog} />
      </>
    )
  );
}
