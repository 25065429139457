// constants
import { CREATE_PAGE_OBJ } from "../context/PageContext";

// components
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import { Add } from "@mui/icons-material";

// hooks
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { usePageContext } from "../hooks/usePageContext";

// functions
import { createPages } from "../functions/createPages";

export default function FormCreatePages({ open, handleClose, trees_id, parent_id, sort_order }) {
  const [pageTitle, setPageTitle] = useState("");
  const { user } = useAuthContext();
  const { dispatch } = usePageContext();
  const navigate = useNavigate();
  const [creating, setCreating] = useState(false);

  const validTitle = (title) => {
    return title.length > 0;
  };

  const handleCreate = async () => {
    if (!validTitle(pageTitle.trim())) {
      return;
    }

    // update database with new version
    setCreating(true);
    const payload = {
      trees_id,
      parent_id,
      sort_order,
      page_title: pageTitle,
      timestamp: new Date().getTime(),
      created_by: user.terbeauUser.uid,
      keywords: null,
      children: [],
    };

    const res = await createPages(payload);

    if (res.pages_id) {
      payload.id = res.pages_id;
      dispatch({
        type: CREATE_PAGE_OBJ,
        payload,
      });
      navigate(`/tree/${res.pages_id}`);
    } else {
      console.error("an error occurred in creating the new page.");
      navigate(`/`);
    }
    setCreating(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
        <DialogTitle>Create New Page</DialogTitle>
        <DialogContent>
          <DialogContentText>Choose a title for the new page</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="page_title"
            label="Page Title"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setPageTitle(e.target.value)}
            value={pageTitle}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={creating}
            variant="outlined"
            loadingPosition="start"
            startIcon={<Add />}
            onClick={handleCreate}
            disabled={!validTitle(pageTitle.trim())}
          >
            <span>Create Page</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
