// constants

// components
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ProviderSchedule from "./ProviderSchedule";

// hooks
import { useState } from "react";
import { usePageContext } from "../hooks/usePageContext";
import { useAuthContext } from "../hooks/useAuthContext";

// functions
import { timestampNowSQL } from "../functions/timestampNowSQL";
import { updateProviders } from "../functions/updateProviders";

const emptyProviderObj = {
  active: 1,
  avatar: null,
  credentials: "",
  first: "",
  last: "",
  roles_id: null,
  salutation: "",
  schedule: [],
};

const spreadRole = (role) => {
  return {
    roles_id: role.id,
    role: role.roles,
    role_sort: role.sort,
    notes_prefix: role.notes_prefix,
    notes_prefix_field: role.notes_prefix_field,
  };
};
export default function FormEditProvider({
  open,
  handleClose, // for update (hotUpdate<bool>, hotUpdateState<obj>), for create (providerObj, bypassConfirm<bool>)
  providerObj,
  createOrUpdate,
}) {
  const { schedulingConfig } = usePageContext();
  const roles = schedulingConfig.roles.filter((r) => r.active === 1);
  if (!providerObj) providerObj = { ...emptyProviderObj, ...spreadRole(roles[0]) };

  const { user } = useAuthContext();

  const [updating, setUpdating] = useState(false);
  const [data, setData] = useState(providerObj);
  const [updatedSchedule, setUpdatedSchedule] = useState(null);

  const handleUpdate = async () => {
    // update database with new version
    setUpdating(true);

    const payload = {
      ...data,
      updated_by_uid: user.terbeauUser.uid,
      updated_on: timestampNowSQL(),
      schedule: updatedSchedule,
    };

    const success = await updateProviders(createOrUpdate, payload);
    setUpdating(false);

    if (success) {
      if (createOrUpdate === "create") payload.id = success.providers_id;
      handleClose(true, payload); // send payload back to calling component for hot update
      setData({ ...emptyProviderObj, ...spreadRole(roles[0]) });
      setUpdatedSchedule(null);
    } else {
      handleClose(false, true); // no parameter = no hot update
    }
  };

  const handleScheduleUpdate = ({ newSchedule }) => {
    setUpdatedSchedule(newSchedule);
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose(false, false)} fullWidth maxWidth={"md"}>
        <DialogTitle> {`${data.first} ${data.last}${data.credentials ? `, ${data.credentials}` : ""}`}</DialogTitle>

        <DialogContent>
          <FormControlLabel
            value="active"
            control={
              <Switch
                color="primary"
                checked={data.active === 1}
                onChange={() => setData({ ...data, active: Math.abs(data.active - 1) })}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={data.active === 1 ? "Active" : "Inactive"}
            labelPlacement="end"
          />
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-controlled"
              label="First name"
              value={data.first}
              onChange={(event) => {
                setData({ ...data, first: event.target.value, salutation: event.target.value });
              }}
            />
            <TextField
              id="outlined-controlled"
              label="Last name"
              value={data.last}
              onChange={(event) => {
                setData({ ...data, last: event.target.value });
              }}
            />
            <TextField
              id="outlined-controlled"
              label="Credentials"
              value={data.credentials}
              onChange={(event) => {
                setData({ ...data, credentials: event.target.value });
              }}
            />
            <Select
              labelId="role-select-label"
              id="role-select"
              value={data.roles_id}
              onChange={(event) => {
                const selectedRole = roles.find((r) => r.id === event.target.value);
                setData({
                  ...data,
                  ...spreadRole(selectedRole),
                });
              }}
            >
              {roles.map((r) => (
                <MenuItem key={r.id} value={r.id}>
                  {r.roles}
                </MenuItem>
              ))}
            </Select>
            <TextField
              id="outlined-controlled"
              label="Salutation | Nickname"
              value={data.salutation}
              onChange={(event) => {
                setData({ ...data, salutation: event.target.value });
              }}
            />
          </Box>

          <DialogContentText sx={{ margin: "1rem 0 .5rem 0" }} variant="h5">
            Clinic Schedule
          </DialogContentText>
          <ProviderSchedule schedule={data.schedule} editMode={true} handleScheduleUpdate={handleScheduleUpdate} />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false, false)}>Cancel</Button>
          <LoadingButton
            loading={updating}
            variant="outlined"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            disabled={createOrUpdate === "create" && (data.first === "" || data.last === "" || data.salutation === "")}
            onClick={() => handleUpdate(providerObj)}
          >
            <span>{createOrUpdate !== "create" ? "Update" : "Create"}</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
