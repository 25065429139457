// constants
import { SET_SELECTED_PAGE_ID } from "../../context/PageContext";

// components
import { Button, ButtonGroup, Container, Typography } from "@mui/material";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";

// hooks
import { useState } from "react";
import { usePageContext } from "../../hooks/usePageContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// functions
import { pageBreadcrumbs } from "../../functions/pageBreadcrumbs";

export default function Search() {
  const { searchString, selectedPageId, pagesIndexCollection, dispatch } = usePageContext();
  const navigate = useNavigate();
  const [foundSet, setFoundSet] = useState([]);

  useEffect(() => {
    if (!searchString) navigate(`/tree/${selectedPageId}`);
    if (!pagesIndexCollection) return;

    let myExp = new RegExp(searchString, "i");
    const searchResult = pagesIndexCollection.filter(
      (p) => p.active === 1 && (p.page_title?.search(myExp) !== -1 || (p.keywords && p.keywords.search(myExp) !== -1))
    );
    setFoundSet((existingItems) => {
      return [...searchResult];
    });
  }, [searchString, selectedPageId, navigate, pagesIndexCollection]);

  return (
    <div>
      {foundSet &&
        foundSet.map((p) => {
          const breadcrumbs = pageBreadcrumbs(pagesIndexCollection, p.id);
          const iLastBreadcrumb = breadcrumbs.length - 1;

          return (
            <Container>
              <ButtonGroup variant="text" aria-label="text button group" sx={{ flexWrap: "wrap" }}>
                {breadcrumbs.map((b, index) => {
                  return (
                    <>
                      {index !== iLastBreadcrumb && (
                        <Button
                          sx={{
                            textTransform: "none",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                            marginTop: "-12px",
                            marginBottom: "4px",
                          }}
                          key={b.id}
                          color={index === iLastBreadcrumb ? "secondary" : "primary"}
                          onClick={() =>
                            dispatch({
                              type: SET_SELECTED_PAGE_ID,
                              payload: b.id,
                            })
                          }
                        >
                          <ReactMarkdown remarkPlugins={[remarkGfm]}>{b.page_title}</ReactMarkdown>
                        </Button>
                      )}
                    </>
                  );
                })}
              </ButtonGroup>
              <Typography
                sx={{ cursor: "pointer" }}
                variant="h5"
                marginTop={-2}
                p={1}
                onClick={() => {
                  dispatch({
                    type: SET_SELECTED_PAGE_ID,
                    payload: breadcrumbs[iLastBreadcrumb].id,
                  });
                  navigate(`/tree/${breadcrumbs[iLastBreadcrumb].id}`);
                }}
              >
                {breadcrumbs[iLastBreadcrumb].page_title}
              </Typography>
            </Container>
          );
        })}
    </div>
  );
}
