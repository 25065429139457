// components
import { Avatar, Box, Container, Typography } from "@mui/material";
import SiteSelector from "./SiteSelector";

// hooks
import { useState } from "react";
import { useThemeContext } from "../hooks/useThemeContext";
import { usePageContext } from "../hooks/usePageContext";

// libraries
import moment from "moment/moment";

// styles
import styles from "./ProviderSchedule.module.css";

const ScheduleAvatars = ({ avatars }) => {
  return (
    <Box className={styles.clinic_badges}>
      {avatars.map((a) => (
        <Avatar
          key={a.id}
          title={`${a.shift}M: ${a.site}`}
          sx={{
            backgroundColor: `var(--childrens-${a.site_class})`, // mpls, stp, mg, wbry, mtka
            fontSize: ".75rem",
            width: 22,
            height: 22,
          }}
        >
          {a.avatar_text}
        </Avatar>
      ))}
    </Box>
  );
};

const ScheduleEditAvatars = ({ shift, day, handleUpdate, providerSchedule }) => {
  const { schedulingConfig } = usePageContext();
  return (
    <Box className={styles.clinic_badges}>
      <SiteSelector
        sites={schedulingConfig.sites}
        shift={shift}
        day={day}
        handleUpdate={handleUpdate}
        providerSchedule={providerSchedule}
      />
    </Box>
  );
};

export default function ProviderSchedule({
  schedule,
  editMode,
  handleScheduleUpdate = () => {
    return null;
  },
}) {
  const { theme } = useThemeContext();
  const { currentTree, schedulingConfig } = usePageContext();
  const columns = currentTree.include_weekends ? [0, 1, 2, 3, 4, 5, 6, 7] : [1, 2, 3, 4, 5];
  const shifts = schedulingConfig.shifts; // A = "AM", P = "PM"
  const [providerSchedule, setProviderSchedule] = useState(schedule);

  const handleUpdate = (dayNumber, shift, updatedSchedule) => {
    const removeDayShift = providerSchedule.filter((ps) => ps.day_number !== dayNumber || ps.shift !== shift);
    setProviderSchedule([...removeDayShift, ...updatedSchedule]);
    handleScheduleUpdate({ newSchedule: [...removeDayShift, ...updatedSchedule] });
  };

  return (
    (editMode || (schedule && schedule.length > 0)) && (
      <Container
        className={styles.schedule_cell}
        style={{
          gridTemplateColumns: `repeat(${columns.length}, 1fr)`,
          outline: theme.palette.mode === "dark" ? "2px solid #222" : "2px solid #eee",
          backgroundColor: theme.palette.mode === "dark" ? "#373737" : "#f6f6f6", // "#79acde"
        }}
      >
        {/* headers for the days of the week */}
        {columns.map((day) => (
          <Typography variant="h6" color={"gray"} key={day}>
            {moment().day(day).format("ddd")}
          </Typography>
        ))}
        {/* loop through the shifts  */}
        {shifts.map((shift) =>
          // row for shift

          // loop through the columns
          columns.map((day) => {
            const avatars = schedule.filter((a) => a.day_number === day && a.shift === shift.id);
            // return <ScheduleAvatars avatars={avatars} key={`${shift}${day}`} />;
            return (
              <>
                {!editMode && <ScheduleAvatars avatars={avatars} key={`${shift}${day}`} />}
                {editMode && (
                  <ScheduleEditAvatars
                    shift={shift}
                    day={day}
                    providerSchedule={avatars}
                    handleUpdate={handleUpdate}
                    key={`${shift.id}${day}`}
                  />
                )}
              </>
            );
          })
        )}
      </Container>
    )
  );
}
