// components
import React, { useCallback } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import { Edit } from "@mui/icons-material";
import FormEditPageContainer from "./EditPageContainer";

// hooks
import { usePageContext } from "../hooks/usePageContext";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TerbeauMarkdown from "./TerbeauMarkdown";

// Decision Cards are no longer displayed in a Masonry Grid for each detail
// Now each decision card occupies the same single column in the layout
// page in the /tree route
// e.g., Phone Script, Additional Information, Keywords, etc.
export default function DecisionCard({ key, field, isEditor }) {
  const { editStatus } = usePageContext();
  const navigate = useNavigate();

  // intercept anchor tags and use navigate hook to keep the navigation
  // inside the react app
  // adapted from Event Listeners in React Components
  // https://www.pluralsight.com/guides/event-listeners-in-react-components
  const interceptAnchorTags = useCallback(
    (e) => {
      if (e.target.tagName === "A") {
        e.preventDefault();
        const route = e.target.getAttribute("href");
        navigate(route);
      }
    },
    [navigate]
  );

  useEffect(() => {
    window.addEventListener("click", interceptAnchorTags);

    // cleanup function
    return () => {
      window.removeEventListener("click", interceptAnchorTags);
    };
  }, [interceptAnchorTags]);

  return (
    <Item key={key} elevation={3}>
      <Bar field={field} editStatus={editStatus} isEditor={isEditor} />
      <Box p={1}>
        <TerbeauMarkdown>{field.content}</TerbeauMarkdown>
      </Box>
    </Item>
  );
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Bar = ({ field, editStatus, isEditor }) => {
  const [openEditor, setOpenEditor] = useState(false);

  const handleClickOpen = () => {
    setOpenEditor(true);
  };

  const handleClose = () => {
    setOpenEditor(false);
  };

  return (
    <div className="bar">
      <Box
        p={0.5}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={1}
        sx={{ backgroundColor: field.color }}
      >
        <Box display="flex" gap={1} alignItems={"center"}>
          {React.cloneElement(field.icon, { color: "text" })}
          <Typography variant="h6">{field.title}</Typography>
        </Box>
        {editStatus && (field.guestEdit || isEditor) && (
          <Button onClick={handleClickOpen} sx={{ justifyContent: "right" }}>
            <Edit fontSize="small" color="action" />
          </Button>
        )}
      </Box>
      <FormEditPageContainer open={openEditor} handleClose={handleClose} field={field} />
    </div>
  );
};
