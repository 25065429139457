// components
import UserList from "./UserList";

// styles

export default function Users() {
  return (
    <div>
      <h2 className="page-title">Manage Users</h2>
      <UserList />
    </div>
  );
}
