// constants
import { SET_SELECTED_PAGE_ID } from "../context/PageContext";

// components
import { Button, ButtonGroup, Container, Typography } from "@mui/material";
import TerbeauMarkdown from "./TerbeauMarkdown";
import { Edit } from "@mui/icons-material";
import FormEditPageContainer from "./EditPageContainer";
import PageTitleControls from "./PageTitleControls";

// hooks
import { usePageContext } from "../hooks/usePageContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

export default function PageTitle({
  breadcrumbs,
  showControls = true, // show Edit page, Feedback, Redirect when appropriate for user
  linkTitle = false, // should the Title of the page also be a link?
  allowEdit = true, // is the Title editable?
  linkBreadcrumbs = true, // should the breadcrumbs be links?
}) {
  const [openTitleEditor, setOpenTitleEditor] = useState(false);
  const { dispatch, editStatus, currentTree } = usePageContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const isEditor = user.terbeauUser?.isEditor(currentTree.id);
  const iLastBreadcrumb = breadcrumbs.length - 1;

  const handleOpenTitleEditor = () => {
    setOpenTitleEditor(true);
  };

  const handleCloseTitleEditor = () => {
    setOpenTitleEditor(false);
  };

  return (
    <Container key="page-title">
      <ButtonGroup key="breadcrumbs" variant="text" aria-label="text button group" sx={{ flexWrap: "wrap" }}>
        {breadcrumbs.map((b, index) => {
          return (
            <>
              {index !== iLastBreadcrumb && (
                <Button
                  sx={{
                    textTransform: "none",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                  disabled={!linkBreadcrumbs}
                  key={b.id}
                  color={index === breadcrumbs.length - 1 ? "secondary" : "primary"}
                  onClick={() => {
                    dispatch({
                      type: SET_SELECTED_PAGE_ID,
                      payload: b.id,
                    });
                    navigate(`/tree/${b.id}`);
                  }}
                >
                  <TerbeauMarkdown>{b.page_title}</TerbeauMarkdown>
                </Button>
              )}
            </>
          );
        })}
      </ButtonGroup>
      {!linkTitle && (
        <Typography
          key="breadcrumbs-title"
          variant="h5"
          marginTop={-1}
          p={1}
          sx={{ display: "flex", justifyContent: "space-between", marginRight: "-24px" }}
        >
          {breadcrumbs[iLastBreadcrumb].page_title}
          {editStatus && isEditor && allowEdit && (
            <Button onClick={handleOpenTitleEditor} sx={{ justifyContent: "right" }}>
              <Edit fontSize="small" color="action" />
            </Button>
          )}
        </Typography>
      )}
      {linkTitle && (
        <Button
          key="breadcrumbs-title"
          sx={{
            textTransform: "none",
            textAlign: "left",
          }}
          color={"secondary"}
          onClick={() => {
            dispatch({
              type: SET_SELECTED_PAGE_ID,
              payload: breadcrumbs[iLastBreadcrumb].id,
            });
            navigate(`/tree/${breadcrumbs[iLastBreadcrumb].id}`);
          }}
        >
          <Typography
            variant="h5"
            marginTop={-1}
            sx={{
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              marginRight: "-24px",
            }}
          >
            {breadcrumbs[iLastBreadcrumb].page_title}
          </Typography>
        </Button>
      )}
      <FormEditPageContainer
        open={openTitleEditor}
        handleClose={handleCloseTitleEditor}
        field={{ id: "page_title", title: "Page Title" }}
      />

      {showControls && <PageTitleControls />}
    </Container>
  );
}
