// hooks
import { createTheme } from "@mui/material";
import { createContext, useReducer } from "react";

export const ThemeContext = createContext();
export const SET_THEME_MODE = "SET_THEME_MODE";
export const TOGGLE_THEME_MODE = "TOGGLE_THEME_MODE";

const childrensTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(0,156,222)",
    },
    secondary: {
      main: "rgb(161,90,149)",
    },
    mode: "light",
  },
});

const childrensDarkTheme = createTheme({
  ...childrensTheme,
  palette: {
    mode: "dark",
  },
});

export const themeReducer = (state, action) => {
  switch (action.type) {
    case SET_THEME_MODE:
      return { ...state, mode: action.payload };
    case TOGGLE_THEME_MODE:
      return { ...state, theme: state.theme.palette.mode === "light" ? childrensDarkTheme : childrensTheme };
    default:
      return state;
  }
};

export const ThemeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(themeReducer, {
    theme: childrensTheme,
  });
  return <ThemeContext.Provider value={{ ...state, dispatch }}>{children}</ThemeContext.Provider>;
};
