// functions
import { apiUrl } from "./apiUrl";

export const updateProviders = async (createOrUpdate, payload) => {
  const url = createOrUpdate !== "create" ? apiUrl("providers/update.php") : apiUrl("providers/create.php");
  let result = null;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    result = createOrUpdate !== "create" ? await res.text() : await res.json();

    if (createOrUpdate === "create") {
      return result; // return the newly created provider id {providers_id: `new providers id`}
    }

    return result.slice(1) === "success"; // otherwise, return the success status from the api call
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the " + createOrUpdate + " provider was aborted");
      return false;
    } else {
      console.error("Could not " + createOrUpdate + " provider information");
      return false;
    }
  }
};
