// constants
import { SET_PAGE_INDEX_COLLECTION } from "../context/PageContext";
import { ENV } from "../constants/constants.js";

// components
import TreeCard from "./TreeCard.js";
import { Typography } from "@mui/material";

// hooks
import { useNavigate } from "react-router-dom";
import { usePageContext } from "../hooks/usePageContext";
import { useAuthContext } from "../hooks/useAuthContext.js";
import { useEffect, useState } from "react";

// functions
import { apiUrl } from "../functions/apiUrl";

// styles
import "./ProjectList.css";

export default function ProjectList({ trees }) {
  const [treeAvailable, setTreeAvailable] = useState(false);
  const { dispatch } = usePageContext();
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    setTreeAvailable(false);
    // determine if there are any trees for the current user
    trees.forEach((t) => {
      setTreeAvailable(
        (prev) =>
          prev ||
          user?.terbeauUser?.isAdmin() ||
          user?.terbeauUser?.isEditor(t.id) ||
          (user?.terbeauUser?.accessToTree(t.id) && t.env === ENV.production)
      );

      // editors have access to all their trees, dev and prod
      setTreeAvailable((prev) => prev || user?.terbeauUser?.isEditor(t.id));

      // all other users only have access to tree that are in production and they have access to
      // includes guest access for now
      setTreeAvailable((prev) => prev || (user?.terbeauUser?.accessToTree(t.id) && t.env === ENV.production));
    });
  }, [trees, user?.terbeauUser, user.terbeauUser.uid]);

  const setTreeSelect = (treeSelect, { option } = { option: "" }) => {
    const pagesUrl = apiUrl("pages/read-index.php", { trees_id: treeSelect.id });

    const controller = new AbortController();

    const fetchData = async () => {
      try {
        const res = await fetch(pagesUrl, { signal: controller.signal, cache: "reload" });
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        const json = await res.json();

        dispatch({
          type: SET_PAGE_INDEX_COLLECTION,
          payload: {
            selectedTreeId: treeSelect.id,
            selectedTree: treeSelect,
            selectedPageId: treeSelect.root_pages_id,
            pagesIndexCollection: json,
          },
        });

        switch (option) {
          case "feedback":
            navigate(`/feedback`);
            break;
          case "providers":
            navigate(`/providers`);
            break;
          default:
            navigate(`/tree/${treeSelect.root_pages_id}`);
        }
      } catch (err) {
        if (err.name === "AbortError") {
          console.error("the fetch was aborted");
        } else {
          console.error("Could not fetch the data");
        }
      }
    };

    fetchData();

    // clean up function
    return () => {
      controller.abort();
    };
  };

  return (
    <>
      {!treeAvailable && (
        <>
          <Typography variant="h6" p={1} mb={0}>
            There are currently no decision trees available.
          </Typography>
          <Typography variant="subtitle1" p={1}>
            Please contact your administrator for access.
          </Typography>
        </>
      )}
      <div className="project-list">
        {trees?.length === 0 && <p>No trees yet!</p>}
        {trees?.map((tree) => {
          // admin has access to all trees, dev and prod
          let show = user?.terbeauUser?.isAdmin() ?? false;

          // editors have access to all their trees, dev and prod
          show = show || user?.terbeauUser?.isEditor(tree.id);

          // all other users only have access to tree that are in production and they have access to
          // includes guest access for now
          show = show || (user?.terbeauUser?.accessToTree(tree.id) && tree.env === ENV.production);

          // show = true; // remove this for production

          return (
            show && (
              <TreeCard
                key={tree.id}
                tree={tree}
                onClick={setTreeSelect}
                isEditor={user?.terbeauUser?.isEditor(tree.id)}
              />
            )
          );
        })}
      </div>
    </>
  );
}
