// components
import Button from "@mui/material/Button";
import { Box, Container, FormControl, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { Link } from "react-router-dom";

// hooks
import { useState } from "react";
import { useLogin } from "../../hooks/useLogin";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isPending, error } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          padding: "20px",
          marginTop: 10,
          maxWidth: "400px",
          border: "1px solid gray",
          borderRadius: "5px",
        }}
      >
        <Typography variant="h5" m={1} textAlign={"center"}>
          Login
        </Typography>
        <FormControl sx={{ margin: "20px" }}>
          <InputLabel htmlFor="my-input">Email address</InputLabel>
          <OutlinedInput
            type="email"
            variant="outlined"
            id="email"
            label="Email address"
            required
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </FormControl>
        <FormControl sx={{ margin: "20px" }}>
          <InputLabel htmlFor="my-input">Password</InputLabel>
          <OutlinedInput
            type="password"
            variant="outlined"
            id="password"
            label="Password"
            required
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </FormControl>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", padding: "0 20px" }}>
          {!isPending && (
            <Button type="submit" fullWidth variant="contained">
              Login
            </Button>
          )}
          {isPending && (
            <Button fullWidth variant="contained" disabled>
              Logging in...
            </Button>
          )}
          {error && <div className="error">{error}</div>}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", mt: "1rem", padding: "0 20px" }}>
          <Link to="/passwordreset">Reset Password</Link>
        </Box>
      </Container>
    </form>
  );
}
