// constants
import { markdownExamples } from "../constants/constants";

// components
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Container, Grid } from "@mui/material";
import TerbeauMarkdown from "./TerbeauMarkdown";

// styles
import "../styles/markdown.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MarkDownCheatSheet({ open, handleClose }) {
  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Guide to Terbeau Markdown
            </Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <Grid container spacing={2} className="page" sx={{ alignItems: "center" }}>
            <Grid item xs={12} sm={4} sx={{ margin: ".5rem 0" }}>
              <Typography variant="subtitle1" textAlign={"center"}>
                Name
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ margin: ".5rem 0" }}>
              <Typography variant="subtitle1" textAlign={"center"}>
                Markdown
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} sx={{ margin: ".5rem 0" }}>
              <Typography variant="subtitle1" textAlign={"center"}>
                Example of Output
              </Typography>
            </Grid>

            {markdownExamples.map((m) => {
              return (
                m.supported && (
                  <>
                    <Grid item xs={12} sm={4} key={`name-${m.name}`}>
                      <Typography variant="h6">{m.name}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} key={`code-${m.name}`}>
                      <Typography variant="p" component="code" dangerouslySetInnerHTML={{ __html: m.code }} />
                    </Grid>
                    <Grid item xs={12} sm={4} className="markdown" key={`markdown-${m.name}`}>
                      <TerbeauMarkdown children={m.markdown} />
                    </Grid>
                    <Grid item xs={12} className="markdown" m={1} key={`divider-${m.name}`}>
                      <Divider />
                    </Grid>
                  </>
                )
              );
            })}
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
}
