// hooks
import { useState } from "react";
import { useSignup } from "../../hooks/useSignup";

// styles
import "./Signup.css";

export default function Signup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [nameLast, setNameLast] = useState("");
  const [nameFirst, setNameFirst] = useState("");
  const [nameDisplay, setNameDisplay] = useState("");
  const { signup, isPending, error } = useSignup();

  const handleSubmit = (e) => {
    e.preventDefault();
    signup(email, password, nameLast, nameFirst, nameDisplay);
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit}>
      <h2>Add User</h2>
      <label>
        <span>email:</span>
        <input required type="email" onChange={(e) => setEmail(e.target.value)} value={email} />
      </label>
      <label>
        <span>password:</span>
        <input required type="password" onChange={(e) => setPassword(e.target.value)} value={password} />
      </label>
      <label>
        <span>last name:</span>
        <input required type="text" onChange={(e) => setNameLast(e.target.value)} value={nameLast} />
      </label>
      <label>
        <span>first name:</span>
        <input required type="text" onChange={(e) => setNameFirst(e.target.value)} value={nameFirst} />
      </label>
      <label>
        <span>display name:</span>
        <input required type="text" onChange={(e) => setNameDisplay(e.target.value)} value={nameDisplay} />
      </label>
      {!isPending && <button className="btn">Sign up</button>}
      {isPending && (
        <button className="btn" disabled>
          loading
        </button>
      )}
      {error && <div className="error">{error}</div>}
    </form>
  );
}
