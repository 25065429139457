// constants
import { ACCESS } from "../../constants/constants";

// components
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Box, Button, Typography } from "@mui/material";

// hooks
import { useEffect, useState } from "react";
import axios from "axios";

// functions
import { apiUrl } from "../../functions/apiUrl";
import { toTitleCase } from "../../functions/toTitleCase";
import TreePrivileges from "../../components/TreePrivileges";
import { compareTerbeauUsers } from "../../functions/sortingFunctions";
import FormEditUser from "../../components/EditUser";

function UserList() {
  const [users, setUsers] = useState(null);
  const [trees, setTrees] = useState(null);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleCloseEditUser = (hotUpdate, payload) => {
    if (hotUpdate) {
      const replacement = { ...payload, privileges: JSON.stringify(payload.privileges) };
      const filtered = users.filter((u) => u.uid !== replacement.uid);
      setUsers([...filtered, replacement].sort(compareTerbeauUsers));
    }
    setSelectedUser(null);
    setOpenEditUser(false);
  };

  useEffect(() => {
    const fetchAllUsers = async () => {
      const url = apiUrl("users/read.php");
      try {
        const res = await axios.get(url);
        res.data.sort(compareTerbeauUsers);
        setUsers(res.data);
      } catch (err) {
        console.error(err);
      }
    };

    const fetchAllTrees = async () => {
      const url = apiUrl("trees/read.php");
      try {
        const res = await axios.get(url);
        setTrees(res.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchAllUsers();
    fetchAllTrees();
  }, []);

  const handleEditUser = (user) => {
    setSelectedUser({ ...user, privileges: user.privileges ? JSON.parse(user.privileges) : [] });
    setOpenEditUser(true);
  };

  const columns = [
    { field: "id", headerName: "uid", valueGetter: (params) => params.row.uid },
    {
      field: "edit",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ padding: ".5rem 0" }}>
            <Button variant="outlined" onClick={() => handleEditUser(params.row)}>
              Edit User
            </Button>
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "User Name",
      width: 200,
      valueGetter: (params) => {
        return `${params.row.name_last}, ${params.row.name_first}`;
      },
      renderCell: (params) => {
        return (
          <Box>
            <Typography variant="subtitle1" fontSize={16}>
              {params.row.name_last}, {params.row.name_first}
            </Typography>
            <Typography variant="subtitle2" color="primary" fontSize={12}>
              {params.row.description}
            </Typography>
          </Box>
        );
      },
    },
  ];

  ACCESS.forEach((a) => {
    columns.push({
      field: a.access,
      headerName: toTitleCase(a.access),
      width: 125,
      renderCell: (params) => {
        return (
          <Box sx={{ margin: ".25rem 0" }}>
            <TreePrivileges access={a.access} user={params.row} trees={trees} />
          </Box>
        );
      },
    });
  });

  return (
    <>
      <h2>All Users</h2>
      {(!users || !trees) && <div>Loading users...</div>}
      {users && trees && (
        <>
          <DataGridPro
            getRowId={(params) => params.uid}
            columns={columns}
            rows={users}
            columnVisibilityModel={{ id: false }}
            getRowHeight={() => "auto"}
            hideFooter
          />
          {selectedUser && (
            <FormEditUser open={openEditUser} user={selectedUser} trees={trees} handleClose={handleCloseEditUser} />
          )}
        </>
      )}
    </>
  );
}

export { UserList as default };
