// constants
import { ACCESS } from "../constants/constants";

export const EditUser_INITIAL_STATE = ({ name_display, name_first, name_last, description, privileges }, trees) => {
  const isAdmin = privileges?.find((p) => p.access === "admin");
  const state = {
    name_display,
    name_first,
    name_last,
    description,
    privileges: [{ id: 0, access: isAdmin ? "Yes" : "No" }],
  };
  trees.forEach((t) => {
    let priv = privileges?.find((p) => {
      return p.trees?.includes(t.id);
    });
    state.privileges.push({ id: t.id, access: priv?.access ?? null });
  });
  return state;
};

export const convertStateToPrivileges = ({ privileges: state }) => {
  const privileges = [];
  const idAdmin = ACCESS.find((a) => a.access === "admin").id;

  ACCESS.forEach((a) => {
    let accessMatch = state.filter((s) => s.access === a.access || a.id === idAdmin);
    let indexOfAdminObject = accessMatch.findIndex((am) => am.id === idAdmin);
    let treeArray = [];
    if (a.access === "admin") {
      if (accessMatch[indexOfAdminObject].access === "Yes") treeArray.push("all");
    } else {
      treeArray = accessMatch.map((a) => a.id);
    }
    if (treeArray.length > 0) privileges.push({ access: a.access, trees: treeArray });
  });
  return privileges;
};

export const getTreeState = (state, tree_id) => {
  return state?.privileges?.find((s) => s.id === tree_id).access;
};

export const editUserFormReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return action.payload;
    case "CHANGE_TREE_ACCESS":
      const remaining_state = state.privileges.filter((s) => s.id !== action.payload.id);
      return { ...state, privileges: [...remaining_state, action.payload] };
    case "CHANGE_INPUT":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};
