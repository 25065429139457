// components
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import TerbeauMarkdown from "./TerbeauMarkdown";
import { LoadingButton } from "@mui/lab";
import MarkDownCheatSheet from "./MarkDownCheatSheet";
import SaveIcon from "@mui/icons-material/Save";

// hooks
import { useThemeContext } from "../hooks/useThemeContext";
import { useEffect, useState } from "react";

//styles
import "./MuiPage.css";
import "../styles/markdown.css";

export default function TerbeauMarkdownEditor({ handleUpdate, handleClose, open, title, revertValue, updating }) {
  const { theme } = useThemeContext();
  const [editFieldContent, setEditFieldContent] = useState(revertValue);
  const [changes, setChanges] = useState(false);

  const [openCheatSheet, setOpenCheatSheet] = useState(false);

  const handleFilterAndUpdate = () => {
    const filterAmpersand = editFieldContent?.replaceAll("&", "and");
    handleUpdate(filterAmpersand);
  };

  const handleOpenCheatSheet = () => {
    setOpenCheatSheet(true);
  };

  const handleCloseCheatSheet = () => {
    setOpenCheatSheet(false);
  };

  useEffect(() => {
    setChanges(editFieldContent !== revertValue);
  }, [editFieldContent, revertValue]);

  useEffect(() => {
    setEditFieldContent(revertValue);
  }, [revertValue]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>Edit {title}</DialogTitle>
        <Container sx={{ display: "flex" }}>
          <Box sx={{ flexGrow: "1", flexBasis: "50%" }}>
            <DialogContent>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label={title + " markdown"}
                multiline
                rows={2}
                onChange={(e) => setEditFieldContent(e.target.value)}
                value={editFieldContent}
                disabled={updating}
              />
            </DialogContent>
          </Box>
          <Box
            sx={{
              flexGrow: "1",
              flexBasis: "50%",
              padding: "20px 14px",
              borderRadius: "4px",
              color: "rgba(0,0,0,0.87)",
            }}
          >
            <Box
              className="page"
              sx={{
                padding: "16.5px 14px",
                borderRadius: "4px",
                border: "1px solid #c3bfbf",
                color: theme.palette.mode === "dark" ? "#FFF" : "currentColor",
                cursor: "pointer",
                userSelect: "none",
              }}
            >
              <TerbeauMarkdown>{editFieldContent}</TerbeauMarkdown>
            </Box>
          </Box>
        </Container>
        <DialogActions>
          <Button onClick={handleOpenCheatSheet}>Markdown Guide</Button>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => setEditFieldContent(revertValue)} disabled={!changes}>
            Revert
          </Button>
          <LoadingButton
            loading={updating}
            variant="outlined"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            onClick={handleFilterAndUpdate}
            disabled={!changes}
          >
            <span>Update</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <MarkDownCheatSheet open={openCheatSheet} handleClose={handleCloseCheatSheet} />
    </>
  );
}
