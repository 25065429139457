import { TerbeauUser } from "../classes/TerbeauUser";
import { getSqlUser } from "./getSqlUser";

export const addTerbeauUser = async (user) => {
  // fetches the user info for the user from the backend and adds
  // it to the user record and returns the augmented record

  if (!user || !user.uid) return user;

  const getTerbeauUser = await getSqlUser(user.uid);

  if (!getTerbeauUser) return user;

  // unpack privileges and create terbeauUser to be added to the user state variable
  const terbeauUser = new TerbeauUser(
    getTerbeauUser.uid,
    getTerbeauUser.name_last,
    getTerbeauUser.name_first,
    getTerbeauUser.name_display,
    getTerbeauUser.privileges ? JSON.parse(getTerbeauUser.privileges) : []
  );

  return { ...user, ...{ terbeauUser } };
};
