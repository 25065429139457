// constants
import { TOGGLE_EDIT_STATUS } from "../context/PageContext";

// components
import { Box, Button, FormControlLabel, Switch, Typography } from "@mui/material";
import { FeedbackRounded } from "@mui/icons-material";
import SwapCallsIcon from "@mui/icons-material/SwapCalls";
import FormCreateFeedback from "./CreateNewFeedback";
import FormUpdateRedirect from "./EditRedirect";

// hooks
import { usePageContext } from "../hooks/usePageContext";
import { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";

export default function PageTitleControls() {
  const [openCreateFeedback, setOpenCreateFeedback] = useState(false);
  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);

  const { dispatch, editStatus, currentTree, currentPageObject } = usePageContext();
  const { user } = useAuthContext();
  const isEditor = user.terbeauUser?.isEditor(currentTree.id);
  const redirect = currentPageObject.alternate_pages_id;

  const handleCloseCreateFeedback = () => {
    setOpenCreateFeedback(false);
  };
  const handleCloseRedirectDialog = () => {
    setOpenRedirectDialog(false);
  };

  return (
    <Box display={"flex"}>
      <FormControlLabel
        control={
          <Switch
            sx={{ margin: "0 0 0 1rem" }}
            size="small"
            checked={editStatus}
            disabled={redirect && !isEditor}
            onChange={() => dispatch({ type: TOGGLE_EDIT_STATUS })}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={
          <Typography color={editStatus ? "primary" : "#777"} fontSize={12}>
            Edit {isEditor ? "page content" : "keywords"}
          </Typography>
        }
      />

      <Box display={"flex"} alignItems={"center"}>
        <Button onClick={() => setOpenCreateFeedback(true)}>
          <FeedbackRounded fontSize="small" color={"primary"} />
          <Typography
            ml={0.25}
            variant="subtitle2"
            color={"primary"}
            sx={{ textTransform: "capitalize", cursor: "pointer" }}
          >
            Feedback
          </Typography>
        </Button>
      </Box>

      {!redirect && isEditor && editStatus && (
        <Box display={"flex"} alignItems={"center"}>
          <Button onClick={() => setOpenRedirectDialog(true)}>
            <SwapCallsIcon fontSize="small" color={"primary"} sx={{ transform: "rotate(90deg)" }} />
            <Typography
              ml={0.25}
              variant="subtitle2"
              color={"primary"}
              sx={{ textTransform: "capitalize", cursor: "pointer" }}
            >
              Redirect this page
            </Typography>
          </Button>
        </Box>
      )}

      <FormCreateFeedback
        open={openCreateFeedback}
        handleClose={handleCloseCreateFeedback}
        pages_id={currentPageObject.id}
      />
      <FormUpdateRedirect open={openRedirectDialog} handleClose={handleCloseRedirectDialog} />
    </Box>
  );
}
