// Build A Tree Array From A Flat Array - Recursion
// from https://dev.to/danielbellmas/build-a-tree-array-from-a-flat-array-recursion-32jn
export const convertPagesToTreeObj = (arr, parent = 0) =>
  arr
    .sort((a, b) => a.sort_order - b.sort_order)
    .filter((item) => item.parent_id === parent)
    .map((child) => ({ ...child, children: convertPagesToTreeObj(arr, child.id) }));

export const oldPagesToTreeObj = (pagesData) => {
  // this function fails because on some calls it will
  // duplicate the members of the children arrays.

  // the converPagesToTreeObj uses a different and simpler approach
  //https://dev.to/danielbellmas/build-a-tree-array-from-a-flat-array-recursion-32jn

  const idMapping = pagesData.reduce((acc, el, i) => {
    acc[el.id] = i;
    return acc;
  }, {});

  // create the tree
  let root;
  pagesData.forEach((el) => {
    // Handle the root element
    if (el.parent_id === 0) {
      root = el;
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = pagesData[idMapping[el.parent_id]];
    // Add our current el to its parent's `children` array
    parentEl.children = [...(parentEl?.children || []), el];
  });
  return [root];
};
