// functions
import { apiUrl } from "./apiUrl";

export const updatePages = async (pageUpdateData) => {
  // use the following object format for pageUpdateData:
  // const sampleData = {
  //   id: "1000",
  //   user_id: "MnWVregrDMUtYpZEKPqFKUpOMzK2",
  //   timestamp: 1690191758677,
  //   field: "keywords",
  //   old_value: "OLD keywords ",
  //   new_value: 'NEW keywords with "quotes" in quotes.',
  // };

  const url = apiUrl("pages/update.php");
  let result = null;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(pageUpdateData),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    result = await res.text();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the update pages was aborted");
      return false;
    } else {
      console.error("Could not update page information");
      return false;
    }
  }
  return result.slice(1) === "success";
};
