import { compareStringsWithoutCase } from "../functions/sortingFunctions";

export default function TreePrivileges({ access, user, trees }) {
  const privileges = user.privileges ? JSON.parse(user.privileges) : [];
  const usersAccessPrivileges = privileges.find((p) => p.access === access);
  if (!usersAccessPrivileges) return;
  const usersTrees = [];
  usersAccessPrivileges.trees.forEach((uapTree) =>
    usersTrees.push(uapTree === "all" ? "all" : trees.find(({ id }) => id === uapTree).name)
  );
  usersTrees.sort(compareStringsWithoutCase);

  return usersTrees.map((uapTree) => <p key={user.uid + access + uapTree}>{uapTree}</p>);
}
