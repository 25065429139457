import { apiUrl } from "./apiUrl";

export const createSqlUser = async (terbeauUser) => {
  const url = apiUrl("users/create.php");
  let json = {};

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(terbeauUser),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    json = await res.json();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the createSqlUser was aborted");
    } else {
      return "Could not createSqlUser";
    }
  }
  return json;
};
