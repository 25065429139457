// functions
import { apiUrl } from "./apiUrl";

export const createFeedback = async (payload) => {
  // use the following object format for createFeedback:
  // const sampleData = {
  // submit_timestamp: '2023-07-04 15:30:00',
  // suggestion: 'This is a suggestion to improve this page',
  // pages_id: 34,
  // submitted_by_name: 'Guest User',
  // submitted_by_uid: "MnWVregrDMUtYpZEKPqFKUpOMzK2",
  // };

  const url = apiUrl("feedback/create.php");
  let result = null;

  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: "data=" + JSON.stringify(payload),
    });

    if (!res.ok) {
      throw new Error(res.statusText);
    }
    result = await res.json();
  } catch (err) {
    if (err.name === "AbortError") {
      console.error("the create feedback was aborted");
    } else {
      return "Could not create new feedback";
    }
  }
  return result;
};
