// constants

// components
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import ProviderSchedule from "./ProviderSchedule";
import FormEditProvider from "./EditProvider";

// hooks
import { useState } from "react";
import { useThemeContext } from "../hooks/useThemeContext";

// functions
import { compareProviders } from "../functions/sortingFunctions";

// styles
import "../styles/markdown.css";

export default function ManageProviders({ dataRows, setDataRows }) {
  const { theme } = useThemeContext();

  const [selectedProvider, setSelectedProvider] = useState(null);
  const [openEditProvider, setOpenEditProvider] = useState(false);
  // const [dataRows, setDataRows] = useState(dataSet.rows);

  const sortByRole = (a, b) => a - b;

  const columns = [
    {
      field: "id",
      headerName: "Edit",
      editable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <Button sx={{ justifyContent: "flex-start" }} onClick={() => handleClickOpen(params.row)}>
            <Edit fontSize="small" color="action" />
          </Button>
        );
      },
    },
    {
      field: "avatar",
      headerName: "",
      editable: false,
      sortable: false,
      width: 40,
      renderCell: (params) => {
        return (
          <Avatar
            sx={{
              opacity: `${params.row.active === 1 ? 1 : 0.5}`,
              width: 28,
              height: 28,
              margin: "1rem 0",
              filter: "drop-shadow(5px 5px 6px #000000)",
            }}
            alt={`${params.row.first} ${params.row.last}`}
            src={`/img/avatars/${params.row.avatar}`}
            className="provider-avatar"
          />
        );
      },
    },
    {
      field: "last",
      headerName: "Provider",
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        return [[params.row.last, params.row.credentials].filter((s) => s !== "").join(" "), params.row.first].join(
          ", "
        );
      },
    },
    {
      field: "role_sort",
      headerName: "Role",
      editable: false,
      valueGetter: (params) => {
        return params.row.role_sort;
      },
      renderCell: (params) => {
        return params.row.role;
      },
      sortComparator: sortByRole,
    },

    {
      field: "schedule",
      headerName: "Schedule",
      editable: false,
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="page" style={{ minWidth: 350, display: "flex", alignItems: "center", padding: "1rem 0" }}>
            <ProviderSchedule schedule={params.row.schedule} editMode={false} />
          </div>
        );
      },
    },
  ];

  // useEffect(() => {
  //   // derived state for the Grid requires useEffect to initialize
  //   // after state variable "data" has loaded and also
  //   // to refesh after state hot update in handleClose
  //   data.rows = dataRows;
  // }, [dataRows]);

  const handleClickOpen = (selectedRow) => {
    setSelectedProvider(selectedRow);
    setOpenEditProvider(true);
  };

  const handleClose = (hotUpdate = false, hotUpdateState = null) => {
    // skip hot update if dialog was canceled
    if (hotUpdate === true) {
      // update local state to reflected changes just saved to backend
      const filtered = dataRows.filter((r) => r.id !== hotUpdateState.id);
      const oldProvider = dataRows.find((r) => r.id === hotUpdateState.id);
      // if the schedule wasn't changed (it will be null), then
      // copy the schedule from the old record,
      // else replace it with the updated version:
      hotUpdateState.schedule =
        hotUpdateState.schedule === null ? [...oldProvider.schedule] ?? null : [...hotUpdateState.schedule];

      setDataRows([...filtered, hotUpdateState].sort(compareProviders));
    }

    setSelectedProvider(null);
    setOpenEditProvider(false);
  };

  return (
    <>
      {dataRows && dataRows.length > 0 && (
        <Box
          sx={{
            width: "100%",
            "& .active": {},
            "& .inactive": {
              backgroundColor: theme.palette.mode === "dark" ? "#373737" : "#ddd", // "#79acde"
              color: "#666",
              fontStyle: "italic",
            },
          }}
        >
          <DataGridPro
            getRowHeight={() => "auto"}
            getCellClassName={(params) => (params.row.active === 1 ? "active" : "inactive")}
            disableRowSelectionOnClick
            columns={columns}
            rows={dataRows}
          />
          {selectedProvider && (
            <FormEditProvider
              createOrUpdate={"update"}
              open={openEditProvider}
              handleClose={handleClose}
              providerObj={selectedProvider}
            />
          )}
        </Box>
      )}

      {dataRows && dataRows.length < 1 && (
        <Typography>
          <br />
          Currently, there are no assigned providers for this tree.
          <br />
          Please contact your system administrator for assistance.
        </Typography>
      )}
    </>
  );
}
