// components
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

// this component replaces any embedded style directives into the markdown
const applyStyleDirectives = (text) => {
  const styleDirectives = [
    { tag: "{caution}", replaceWith: '<span class="caution"></span>' },
    { tag: "{urgent}", replaceWith: '<span class="urgent"></span>' },
    { tag: "&", replaceWith: "and" },
  ];

  let result = text;
  styleDirectives.forEach((d) => {
    result = result?.replaceAll(d.tag, d.replaceWith);
  });
  return result;
};

export default function TerbeauMarkdown({ children }) {
  return (
    <ReactMarkdown
      className="markdown"
      children={applyStyleDirectives(children)}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
    />
  );
}
