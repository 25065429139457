// From: https://blog.theodo.com/2020/11/react-resizeable-split-panels/

// hooks
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

// styles
import styles from "./SplitView.module.css";

const MIN_WIDTH = 150;

const LeftPanel = ({ children, leftWidth, setLeftWidth }) => {
  const leftRef = useRef(); // By doing this, the properties of that element are now accessible under leftRef.current.

  useEffect(() => {
    if (leftRef.current) {
      if (!leftWidth) {
        setLeftWidth(leftRef.current?.clientWidth);
        return;
      }
      leftRef.current.style.width = `${leftWidth}px`;
    }
  }, [leftRef, leftWidth, setLeftWidth]);

  return <div ref={leftRef}>{children}</div>;
};

export default function SplitView({ left, right, className, showLeftPanel = true }) {
  const [leftWidth, setLeftWidth] = useState();
  const [separatorXPosition, setSeparatorXPosition] = useState();
  const [dragging, setDragging] = useState();
  const splitPaneRef = useRef();

  const onMouseDown = (e) => {
    setSeparatorXPosition(e.clientX);
    setDragging(true);
  };

  const onMouseMove = (e) => {
    if (dragging) e.preventDefault();
    onMove(e.clientX);
  };

  const onTouchMove = (e) => {
    if (dragging) e.preventDefault();
    onMove(e.clientX);
  };

  const onMouseUp = () => {
    setSeparatorXPosition(undefined);
    setDragging(false);
  };

  const onTouchStart = (e) => {
    setSeparatorXPosition(e.touches[0].clientX);
    setDragging(true);
  };

  const onMove = (clientX) => {
    if (dragging && leftWidth && separatorXPosition) {
      const newLeftWidth = leftWidth + clientX - separatorXPosition;
      setSeparatorXPosition(clientX);

      if (newLeftWidth < MIN_WIDTH) {
        setLeftWidth(MIN_WIDTH);
        return;
      }

      if (splitPaneRef.current) {
        const splitPaneWidth = splitPaneRef.current.clientWidth;

        if (newLeftWidth > splitPaneWidth - MIN_WIDTH) {
          setLeftWidth(splitPaneWidth - MIN_WIDTH);
          return;
        }
      }

      setLeftWidth(newLeftWidth);
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("mouseup", onMouseUp);
    if (className) splitPaneRef.current.classList.add(className); // add user style class
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("mouseup", onMouseUp);
    };
  });

  return (
    <div style={{ maxWidth: "min(100vw, 1200px" }} ref={splitPaneRef} className={styles.splitView}>
      {showLeftPanel && (
        <>
          <LeftPanel leftWidth={leftWidth} setLeftWidth={setLeftWidth} children={left} />
          <div
            className={styles.dividerHitbox}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            onTouchEnd={onMouseUp}
          >
            <div className={styles.divider} />
          </div>
        </>
      )}
      <div className={styles.rightPane}>{right}</div>
    </div>
  );
}
