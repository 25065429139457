// constants
import { SET_PAGE_OBJ, SET_SELECTED_PAGE_ID } from "../../context/PageContext";

// components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import TreeSelector from "../../components/TreeSelector";
import MuiPage from "../../components/MuiPage";
import SplitView from "../../components/SplitView";

// hooks
import { usePageContext } from "../../hooks/usePageContext";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";

// functions
import { apiUrl } from "../../functions/apiUrl";
import { pageBreadcrumbs } from "../../functions/pageBreadcrumbs";

const drawerWidth = 360;

export default function TreeSlider() {
  // thisis the place where the page data should be fetched...
  const { dispatch, selectedPageId, pagesIndexCollection, searchString } = usePageContext();
  const navigate = useNavigate();
  const { pages_id } = useParams();
  const [showLeftPanel, setShowLeftPanel] = useState(false);

  const toggleLeftPanel = () => {
    setShowLeftPanel((prev) => !prev);
  };

  // check to see if pages_id is valid for the current tree
  const isValid = pagesIndexCollection?.find((p) => p.id === parseInt(pages_id));
  if (!isValid) navigate(`/`);

  // fetch page data
  useEffect(() => {
    const controller = new AbortController(); // allow interception of any API request
    const signal = controller.signal; // when we want to cancel the fetch request, we send a signal and the request is cancelled immediately

    const breadcrumbs = pageBreadcrumbs(pagesIndexCollection, pages_id);

    // fetch the data and update states...
    const pagesUrl = apiUrl("pages/read-with-children.php", {
      id: pages_id,
    });

    fetch(pagesUrl, { signal, cache: "reload" }) // signal from AbortController will cancel the request if cleanup function is activated by another request before the api call completes
      .then((res) => res.json())
      .then((data) => {
        dispatch({ type: SET_SELECTED_PAGE_ID, payload: pages_id });
        dispatch({ type: SET_PAGE_OBJ, payload: { ...data[0], breadcrumbs } });
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.error("cancelled");
        } else {
          //todo:handle error
        }
      });

    // clean up function
    return () => {
      controller.abort();
    };
  }, [pages_id, pagesIndexCollection, dispatch]);

  useEffect(() => {
    if (searchString) navigate(`/search`);
  }, [searchString, navigate]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <SplitView
        showLeftPanel={showLeftPanel}
        left={pagesIndexCollection && <TreeSelector pages={pagesIndexCollection} width={drawerWidth} />}
        right={
          selectedPageId && pagesIndexCollection && <MuiPage open={showLeftPanel} toggleLeftPanel={toggleLeftPanel} />
        }
      />
    </Box>
  );
}
