// components
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Avatar, Box } from "@mui/material";

// hooks
import { useState } from "react";

// functions
import { compareSitesBySortOrder } from "../functions/sortingFunctions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SiteSelector({ sites, shift, day, handleUpdate, providerSchedule }) {
  const [selectedSites, setSelectedSites] = useState(providerSchedule.map((ps) => ps.sites_id));
  sites.sort(compareSitesBySortOrder);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSites(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    const filtered = sites.filter((s) => value.includes(s.sites_id));
    const augmentValue = filtered.map((v) => {
      return {
        day_number: day,
        shift: shift.id,
        ...v,
      };
    });
    handleUpdate(day, shift.id, augmentValue);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 125 }}>
        <InputLabel id="demo-multiple-checkbox-label">{`${shift.id}M Sites`}</InputLabel>
        <Select
          sx={{ display: "flex" }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedSites}
          onChange={handleChange}
          input={<OutlinedInput label={`${shift.id}M Sites`} />}
          renderValue={(selected) => {
            // sort array of selected IDs based on sorted sites array
            selected = sites.filter((s) => selected.includes(s.sites_id)).map((f) => f.sites_id);
            return (
              <Box sx={{ display: "flex", gap: ".5rem" }}>
                {selected.map((selectedSite) => {
                  const siteObj = sites.find((s) => s.sites_id === selectedSite);
                  return (
                    <Avatar
                      key={selectedSite}
                      sx={{
                        backgroundColor: `var(--childrens-${siteObj.site_class})`, // mpls, stp, mg, wbry, mtka
                        fontSize: ".75rem",
                        width: 22,
                        height: 22,
                      }}
                    >
                      {siteObj.avatar_text}
                    </Avatar>
                  );
                })}
              </Box>
            );
          }}
          MenuProps={MenuProps}
        >
          {sites.map((site) => (
            <MenuItem key={site.sites_id} value={site.sites_id}>
              <Checkbox checked={selectedSites.findIndex((s) => parseInt(s) === parseInt(site.sites_id)) > -1} />
              <ListItemText primary={site.site} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
