import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC2YE54EreKJlEbYz8f59pZ3JIocxlWPpE",
  authDomain: "terbeau-74db3.firebaseapp.com",
  projectId: "terbeau-74db3",
  storageBucket: "terbeau-74db3.appspot.com",
  messagingSenderId: "342326565278",
  appId: "1:342326565278:web:afe5645fae4c9b7b385e8f",
};
// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectAuth, projectStorage, timestamp };
