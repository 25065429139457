// constants
// import { ACCESS } from "../../constants/constants";

// components
import SaveIcon from "@mui/icons-material/Save";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// hooks
import { useEffect, useReducer, useState } from "react";

// functions
import {
  editUserFormReducer,
  EditUser_INITIAL_STATE,
  convertStateToPrivileges,
} from "../functions/editUserFormReducer";
import { updateSqlUser } from "../functions/updateSqlUser";
import UserPrivileges from "./UserPrivileges";

export default function FormEditUser({ open, user, trees, handleClose }) {
  const [state, dispatch] = useReducer(editUserFormReducer, null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (user && trees) {
      dispatch({
        type: "INITIALIZE",
        payload: EditUser_INITIAL_STATE(user, trees),
      });
    }
  }, [user, trees]);

  const handleUpdate = async () => {
    // update database with new version
    setUpdating(true);

    const userObj = {
      uid: user.uid,
      name_last: state.name_last,
      name_first: state.name_first,
      name_display: state.name_display,
      description: state.description,
      privileges: convertStateToPrivileges(state),
    };

    const success = await updateSqlUser(userObj);
    setUpdating(false);

    if (success) {
      handleClose(true, userObj); // send payload back to calling component for hot update
    } else {
      handleClose(false); // no parameter = no hot update
    }
  };

  const handleInputChange = (e) => {
    dispatch({
      type: "CHANGE_INPUT",
      payload: { name: e.target.id, value: e.target.value },
    });
  };

  return (
    <>
      <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth={"md"}>
        <DialogTitle> {`${user.name_first} ${user.name_last}`}</DialogTitle>
        {state && (
          <DialogContent>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, maxWidth: "100%" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField id="name_first" label="First name" value={state.name_first} onChange={handleInputChange} />
              <TextField id="name_last" label="Last name" value={state.name_last} onChange={handleInputChange} />
              <TextField
                id="name_display"
                label="Display Name"
                value={state.name_display}
                onChange={handleInputChange}
              />
              <TextField
                id="description"
                label="Description/Position"
                value={state.description}
                onChange={handleInputChange}
                fullWidth
              />
            </Box>

            <DialogContentText sx={{ margin: "1rem 0 .5rem 0" }} variant="h5">
              Privileges
            </DialogContentText>
            <UserPrivileges dispatch={dispatch} state={state} trees={trees} />
          </DialogContent>
        )}

        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <LoadingButton
            loading={updating}
            variant="outlined"
            loadingPosition="start"
            startIcon={<SaveIcon />}
            onClick={() => handleUpdate(user)}
          >
            <span>Update</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
