// hooks
import { createContext, useReducer } from "react";

// functions
import { pageBreadcrumbs } from "../functions/pageBreadcrumbs";
import { compareTreeIndex } from "../functions/sortingFunctions";
import { getPageUpdateText } from "../functions/getPageUpdateText";

export const PageContext = createContext();

// reducer types
// export const SET_SELECTED_TREE_ID = "SET_SELECTED_TREE_ID";
export const SET_SELECTED_PAGE_ID = "SET_SELECTED_PAGE_ID";
export const SET_PAGE_INDEX_COLLECTION = "SET_PAGE_INDEX_COLLECTION";
export const SET_PAGE_OBJ = "SET_PAGE_OBJ";
export const SET_LAST_PAGE_UPDATE = "SET_LAST_PAGE_UPDATE";
export const UPDATE_PAGE_OBJ = "UPDATE_PAGE_OBJ";
export const UPDATE_PAGES_PROVIDERS = "UPDATE_PAGES_PROVIDERS";
export const HOT_UPDATE_FEEDBACK = "HOT_UPDATE_FEEDBACK";
export const CREATE_PAGE_OBJ = "CREATE_PAGE_OBJ";
export const SET_PAGE_OBJECT_NOT_READY = "SET_PAGE_OBJECT_NOT_READY";
export const SET_HIDE_TREE_SELECTOR = "SET_HIDE_TREE_SELECTOR";
export const TOGGLE_HIDE_TREE_SELECTOR = "TOGGLE_HIDE_TREE_SELECTOR";
export const SET_SEARCH_STRING = "SET_SEARCH_STRING";
export const TOGGLE_EDIT_STATUS = "TOGGLE_EDIT_STATUS";
export const UPDATE_REDIRECT = "UPDATE_REDIRECT";
export const SET_SCHEDULING_CONFIG = "SET_SCHEDULING_CONFIG";

const pageReducer = (state, action) => {
  const updatePagesIndexCollection = ({ id, page_title, keywords, timestamp }) => {
    const grab = state.pagesIndexCollection.find((i) => i.id === id);
    grab.page_title = page_title;
    grab.keywords = keywords;
    grab.timestamp = timestamp;
    const filtered = state.pagesIndexCollection.filter((i) => i.id !== id);
    return [...filtered, grab].sort(compareTreeIndex); // return sorted collection
  };

  const hotUpdateFeedback = ({ action: feedback }) => {
    const elementsIndex = state.currentPageObject.feedback.findIndex((i) => i.id === feedback.id);
    let newFeedback = [...state.currentPageObject.feedback];

    if (elementsIndex > -1) {
      // the feedback record currently exists
      feedback.updated = 1;
      newFeedback[elementsIndex] = { ...newFeedback[elementsIndex], ...feedback };
    } else {
      // a new feedback record was just added
      feedback.updated = 0; // prevent newly added item from displaying an update summary box (null is auto entered on the backend, but not created in the payload)
      newFeedback = [feedback, ...newFeedback];
    }

    let newPageObject = state.currentPageObject;
    newPageObject.feedback = newFeedback;

    return newPageObject;
  };

  const hotUpdatePagesProviders = ({ crud, provider }) => {
    let newProviders = state.currentPageObject.providers;
    switch (crud) {
      case "create":
        newProviders = [...newProviders, provider];
        return { ...state.currentPageObject, providers: newProviders };
      case "delete":
        newProviders = newProviders.filter((p) => p.id !== provider.id);
        return { ...state.currentPageObject, providers: newProviders };
      case "update":
        // combine delete and create
        newProviders = newProviders.filter((p) => p.id !== provider.id);
        newProviders = [...newProviders, provider];
        return { ...state.currentPageObject, providers: newProviders };
      default:
        return state.currentPageObject;
    }
  };

  const hotUpdateAlternatePageId = (alternatePagesId) => {
    const updatedPageObject = { ...state.currentPageObject, alternate_pages_id: alternatePagesId };
    const pageId = parseInt(state.selectedPageId);
    const newIndexObj = state.pagesIndexCollection.find((i) => i.id === pageId);
    newIndexObj.alternate_pages_id = alternatePagesId;
    const minusTarget = state.pagesIndexCollection.filter((i) => i.id !== pageId);

    const updatedIndexCollection = [...minusTarget, newIndexObj];
    return {
      updatedPageObject,
      updatedIndexCollection,
    };
  };

  switch (action.type) {
    case SET_SCHEDULING_CONFIG:
      return {
        ...state,
        schedulingConfig: action.payload,
      };
    case SET_PAGE_INDEX_COLLECTION:
      return {
        ...state,
        pagesIndexCollection: action.payload.pagesIndexCollection,
        selectedPageId: action.payload.selectedPageId,
        pageObjectReady: false,
        selectedTreeId: action.payload.selectedTreeId,
        currentTree: action.payload.selectedTree,
        searchString: null,
        lastPageUpdate: "",
      };
    case SET_PAGE_OBJ:
      return {
        ...state,
        currentPageObject: action.payload,
        pageObjectReady: true,
        lastPageUpdate: getPageUpdateText(action.payload.timestamp),
      };

    case CREATE_PAGE_OBJ:
      action.payload.breadcrumbs = pageBreadcrumbs([...state.pagesIndexCollection, action.payload], action.payload.id);
      return {
        ...state,
        pagesIndexCollection: [...state.pagesIndexCollection, action.payload],
        currentPageObject: action.payload,
        pageObjectReady: true,
      };

    case UPDATE_PAGE_OBJ:
      const update = updatePagesIndexCollection(action.payload);
      return {
        ...state,
        pagesIndexCollection: update,
        currentPageObject: action.payload,
        lastPageUpdate: action.payload.timestamp,
        pageObjectReady: true,
      };

    case UPDATE_REDIRECT:
      const hotUpdateAPID = hotUpdateAlternatePageId(action.alternatePagesId);
      return {
        ...state,
        currentPageObject: hotUpdateAPID.updatedPageObject,
        pagesIndexCollection: hotUpdateAPID.updatedIndexCollection,
      };

    case UPDATE_PAGES_PROVIDERS:
      const hotUpdatePP = hotUpdatePagesProviders(action.payload);
      return { ...state, currentPageObject: hotUpdatePP };

    case HOT_UPDATE_FEEDBACK:
      const hotUpdate = hotUpdateFeedback(action);
      return {
        ...state,
        currentPageObject: hotUpdate,
        lastPageUpdate: getPageUpdateText(hotUpdate.timestamp),
      };

    case SET_LAST_PAGE_UPDATE:
      return { ...state, lastPageUpdate: action.payload };

    case SET_PAGE_OBJECT_NOT_READY:
      return { ...state, pageObjectReady: false };

    case SET_SELECTED_PAGE_ID:
      return {
        ...state,
        selectedPageId: action.payload,
        searchString: null,
        pageObjectReady: false,
        lastPageUpdate: "",
      };

    case SET_HIDE_TREE_SELECTOR:
      return { ...state, hideTreeSelector: action.payload };

    case TOGGLE_HIDE_TREE_SELECTOR:
      return { ...state, hideTreeSelector: !state.hideTreeSelector };

    case TOGGLE_EDIT_STATUS:
      return { ...state, editStatus: !state.editStatus };

    case SET_SEARCH_STRING:
      return { ...state, searchString: action.payload };

    default:
      return state;
  }
};

export const PageContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(pageReducer, {
    selectedTreeId: null,
    currentTree: null,
    selectedPageId: null,
    pagesIndexCollection: null,
    treeObj: [],
    hideTreeSelector: true,
    searchString: "",
    editStatus: false,
    currentPageObject: null,
    pageObjectReady: false,
    lastPageUpdate: "",
    schedulingConfig: null,
  });
  return <PageContext.Provider value={{ ...state, dispatch }}>{children}</PageContext.Provider>;
};
